import React, { useEffect, useState, Fragment } from "react";
import {
  where,
  query,
  collection,
  getDocs,
  getFirestore,
  setDoc,
  doc,
  limit,
  orderBy,
} from "firebase/firestore";
import { app } from "../firebase";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import Project from "./Project";
import { admins } from "./utils/admins";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Transition, Dialog } from "@headlessui/react";
import ProjectListItem from "./ProjectListItem";

const Projects = ({ userEmail, toggleFn, fromPage }) => {
  const [projectsList, setProjectsList] = useState([]);
  const db = getFirestore(app);
  const loggedInUser = useSelector(state => (state.loggedInUser))

  var isAdmin = false;
  if(loggedInUser){
    isAdmin = JSON.parse(loggedInUser)?.isAdmin;}
  

  const [isNewProj, setIsNewProj] = useState(false);
  const [slide, setSlide] = useState("projects");
  const [project, setProject] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [ids, setIds] = useState([]);
  const [mids, setMids] = useState([]);

  // Data to be uploaded
  const [projectName, setprojectName] = useState("");
  const [pONumber, setPONumber] = useState("");
  const [user, setUser] = useState(JSON.parse(loggedInUser)?.email);
  const [uEmail, setUEmail] = useState("");
  const [currTab, setCurrTab] = useState("pending");

  // function to get the user info
  const getUsers = async () => {
    const users = await getDocs(query(collection(db, "users")));
    return users.docs.map((doc) => doc.data());
  };

  const fetchUsers = async () => {
    await getUsers().then((data) => {
      setUsers(data);
    });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  // Search and filter users
  const searchFn = (ids, input) => {
    const regex = new RegExp(input.split("").join(".*"), "i");
    setMids(ids.filter((id) => regex.test(id.email)));
  };

  useEffect(() => {
    searchFn(users, uEmail);
  }, [uEmail]);
  const inputFn = (value) => {
    setUser(value);
    setUEmail(value);
  };

  const toggleNewProj = () => {
    setIsNewProj(!isNewProj);
    setUEmail("");
    setUser(JSON.parse(loggedInUser)?.email);
    setprojectName("");
  };

  // function to get your projects

  const getProjects = async () => {
    const projects = await getDocs(
      query(collection(db, "orders"), where("user", "==", JSON.parse(loggedInUser)?.email), orderBy("time", "desc"))
    );
    return projects.docs.map((doc) => doc.data());
  };

  // function to get user's projects

  const getUserProjects = async () => {
    const projects = await getDocs(
      query(collection(db, "orders"), where("user", "==", userEmail), orderBy("time", "desc"))
    );
    return projects.docs.map((doc) => doc.data());
  };

  // function to get all the projects as Admin

  const getAllProjects = async (days) => {
    var currTime = Date.now();
    var duration = currTime - days * 24 * 60 * 60 * 1000;
    const projects = await getDocs(
      query(collection(db, "orders"), where("time", ">=", duration), orderBy("time", "desc"))
    );
    return projects.docs.map((doc) => doc.data());
  };

  const getAllProjectsDate = async (date) => {
    var dateStart = Date.parse(date);
    var dateEnd = dateStart + 24 * 60 * 60 * 1000;
    const projects = await getDocs(
      query(
        collection(db, "orders"),
        where("time", ">=", dateStart),
        where("time", "<=", dateEnd),
        orderBy("time", "asc")
      )
    );
    return projects.docs.map((doc) => doc.data());
  };

  // Fetch Projects
  const fetchProjects = async () => {
    if (isAdmin) {
      if (userEmail == "admin") {
        await getAllProjects(1).then((data) => {
          setProjectsList(data);
        });
      } else {
        await getUserProjects().then((data) => {
          setProjectsList(data);
        });
      }
    } else {
      await getProjects().then((data) => {
        setProjectsList(data);
      });
    }
  };
  useEffect(() => {
    fetchProjects();
  }, [slide]);

  // Set Time Limit

  async function switchTime(days) {
    await getAllProjects(days).then((data) => {
      setProjectsList(data);
    });
    console.log(days);
  }

  async function switchDate(day) {
    await getAllProjectsDate(day).then((data) => {
      setProjectsList(data);
    });
    console.log(day);
  }

  // Submit Project

  const submitProject = () => {
    try {
      const orderRef = collection(db, "orders");
      var currTime = Date.now();
      const randomString = Math.random().toString(36).substring(2, 7);
      var stat = 0;
      setDoc(doc(orderRef, `${currTime}${randomString}`), {
        projectID: `${currTime}${randomString}`,
        projectName: projectName,
        pONumber: pONumber,
        user: user,
        time: currTime,
        adminremarks: "",
        remarks: "",
        status: stat,
      });
      setIsNewProj(false);
      fetchProjects();
    } catch (e) {
      console.log(e);
    }
    setUEmail("");
    setUser(JSON.parse(loggedInUser)?.email);
    setprojectName("");
  };
  const selectOrder = (order) => {
    setSlide("order");
    setProject(order);
    toggleFn();
  };

  const goBackFn = () => {
    setSlide("projects");
    toggleFn();
  };

  const search = (data) => {
    return data.filter(
      (item) =>
        item.projectName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.user.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <>
      <div className={`flex text-xs md:text-sm mx-3 flex-col ${fromPage && 'mt-16'} items-center min-h-screen`}>
        <div className=" w-full p-4 items-center justify-center rounded-lg ">
          {slide == "projects" ? (
            <>
              {/* List section */}
              <div className=" flex flex-col">
                  
                {/* New Order  */}
                <div className="flex flex-col items-center">
                  <div className="flex">
                    {isNewProj ? (
                      <motion.button
                        onClick={() => toggleNewProj()}
                        whileTap={{ translateX: 4 }}
                        className=" shadow-md items-center pl-7 pr-7 pt-2 pb-2 hover:shadow-cust-red flex cursor-pointer border border-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white  rounded-full "
                      >
                        {" "}
                        Cancel
                      </motion.button>
                    ) : (
                      <motion.button
                        onClick={() => toggleNewProj()}
                        whileTap={{ translateX: 4 }}
                        className=" shadow-md items-center pl-7 pr-7 pt-2 pb-2 hover:shadow-cust-red flex cursor-pointer border border-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white  rounded-full "
                      >
                        <FiPlus /> New Order
                      </motion.button>
                    )}
                  </div>

                  {/* <Transition appear show={isNewProj} as={Fragment}>
                  <Dialog
                    as="div"
                    className=" relative z-10"
                    onClose={() => setIsNewProj(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-fit transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <motion.button
                              onClick={() => toggleNewProj()}
                              whileTap={{ translateY: 4 }}
                              className=" shadow-md w-fit m-2 items-center pl-8 pr-8 pt-2 pb-2 hover:shadow-cust-red flex cursor-pointer border border-cust-red ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white  rounded-full "
                            >
                              Cancel
                            </motion.button>

                            <div className=" grid text-sm grid-cols-1">
                              <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                                <span className=" w-20 flex items-center justify-center right-0  h-full text-white ">
                                  Order
                                </span>
                                <input
                                  type="text"
                                  placeholder="Name"
                                  className=" h-full text-center bg-white w-3/4 focus:ring-0 outline-none border-none"
                                  name="projectName"
                                  id="projectName"
                                  required
                                  onChange={(e) =>
                                    setprojectName(e.target.value)
                                  }
                                />
                              </div>
                              <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                                <span className=" w-20 flex items-center justify-center right-0  h-full text-white ">
                                  PO
                                </span>
                                <input
                                  type="text"
                                  placeholder="Number"
                                  className=" h-full text-center bg-white w-3/4 focus:ring-0 outline-none border-none"
                                  name="projectName"
                                  id="projectName"
                                  onChange={(e) => setPONumber(e.target.value)}
                                />
                              </div>
                              {isAdmin && (
                                <div>
                                  <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                                    <span className=" w-20 flex items-center justify-center right-0  h-full text-white ">
                                      User
                                    </span>
                                    <input
                                      type="text"
                                      className=" h-full text-center bg-white w-3/4 focus:ring-0 outline-none border-none"
                                      name="user"
                                      id="user"
                                      placeholder="Email address"
                                      value={uEmail}
                                      onChange={(e) =>
                                        setUEmail(e.target.value)
                                      }
                                    />
                                  </div>

                                  <ul
                                    className={`relative max-h-0 z-10 bg-white `}
                                  >
                                    {mids?.map(
                                      (id) =>
                                        uEmail != "" &&
                                        uEmail != id.email && (
                                          <li
                                            key={id.email}
                                            onClick={() => inputFn(id.email)}
                                            className={`bg-transparent border cursor-pointer bg-white hover:bg-cust-red hover:text-white w-80 rounded-full m-1 text-center`}
                                          >
                                            {id.email} ({id.name})
                                          </li>
                                        )
                                    )}
                                  </ul>
                                </div>
                              )}
                              {projectName != "" && (
                                <div className="flex justify-around m-3 ">
                                  <motion.button
                                    onClick={() => submitProject()}
                                    whileTap={{ translateX: 4 }}
                                    className=" shadow-md hover:shadow-cust-red  cursor-pointer border border-cust-red w-32 ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
                                  >
                                    CREATE
                                  </motion.button>
                                </div>
                              )}
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition> */}

                  {isNewProj && (
                    <div className=" grid text-sm grid-cols-1">
                      <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                        <span className=" w-20 flex items-center justify-center right-0  h-full text-white ">
                          Order
                        </span>
                        <input
                          type="text"
                          placeholder="Name"
                          className=" h-full text-center bg-white w-3/4 focus:ring-0 outline-none border-none"
                          name="projectName"
                          id="projectName"
                          required
                          onChange={(e) => setprojectName(e.target.value)}
                        />
                      </div>
                      <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                        <span className=" w-20 flex items-center justify-center right-0  h-full text-white ">
                          PO
                        </span>
                        <input
                          type="text"
                          placeholder="Number"
                          className=" h-full text-center bg-white w-3/4 focus:ring-0 outline-none border-none"
                          name="projectName"
                          id="projectName"
                          onChange={(e) => setPONumber(e.target.value)}
                        />
                      </div>
                      {isAdmin && (
                        <div>
                          <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                            <span className=" w-20 flex items-center justify-center right-0  h-full text-white ">
                              User
                            </span>
                            <input
                              type="text"
                              className=" h-full text-center bg-white w-3/4 focus:ring-0 outline-none border-none"
                              name="user"
                              id="user"
                              placeholder="Email address"
                              value={uEmail}
                              onChange={(e) => setUEmail(e.target.value)}
                            />
                          </div>

                          <ul className={`relative max-h-0 z-10 bg-white `}>
                            {mids?.map(
                              (id) =>
                                uEmail != "" &&
                                uEmail != id.email && (
                                  <li
                                    key={id.email}
                                    onClick={() => inputFn(id.email)}
                                    className={`bg-transparent border cursor-pointer bg-white hover:bg-cust-red hover:text-white w-80 rounded-full m-1 text-center`}
                                  >
                                    {id.email} ({id.name})
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      )}
                      {projectName != "" && (
                        <div className="flex justify-around m-3 ">
                          <motion.button
                            onClick={() => submitProject()}
                            whileTap={{ translateX: 4 }}
                            className=" shadow-md hover:shadow-cust-red  cursor-pointer border border-cust-red w-32 ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
                          >
                            CREATE
                          </motion.button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className=" flex flex-col mt-3 gap-2 ">
                  { isAdmin && userEmail === "admin" && <div className=" w-full flex flex-col items-center justify-center">
                    <input
                      type="text"
                      className=" h-full rounded-full m-3 mr-5 text-center focus:shadow bg-white w-fit focus:ring-0 outline-none border-none "
                      name="search"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <div className=" flex gap-1 justify-center items-center ">
                      <select
                        onChange={(e) => switchTime(e.target.value)}
                        className=" border cursor-pointer hover:shadow focus:shadow flex border-cust-red focus:ring-0 outline-none overflow-clip rounded-full text-center bg-white justify-between items-center "
                        name="durat"
                        id="durat"
                      >
                        <option value={1}>last 24 hrs</option>
                        <option value={3}>last 72 hrs</option>
                      </select>
                      <span> OR </span>
                      <div>
                        <input
                          className=" border cursor-pointer hover:shadow focus:shadow flex border-cust-red focus:ring-0 outline-none overflow-clip rounded-full text-center bg-white justify-between items-center "
                          type="date"
                          name="durat-date"
                          onChange={(e) => switchDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>}
                  {isAdmin ? (
                    <>
                      <div className=" flex justify-around items-center p-3 ">
                        <span className=" w-40"> Order Name </span>
                        <span className=" w-40"> User's Name </span>
                        <span className=" w-40">Date & Time</span>
                        <span className=" w-40">Status</span>
                      </div>
                      <div className=" flex flex-col gap-1">
                        {projectsList.length > 0 &&
                          search(projectsList).map((project) => (
                            <ProjectListItem
                              key={project.projectID}
                              clickFn={selectOrder}
                              project={project}
                            />
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" flex justify-around items-center p-3 ">
                        <span className=" w-40"> Order Name </span>
                        <span className=" w-40">Date & Time</span>
                        <span className=" w-40">Status</span>
                      </div>
                      <div className=" flex flex-col gap-2">
                        {projectsList.length > 0 &&
                          search(projectsList).map((project) => (
                            <ProjectListItem
                              key={project.projectID}
                              clickFn={selectOrder}
                              project={project}
                            />
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className=" flex flex-col gap-3">
              <button
                onClick={() => goBackFn()}
                className=" flex gap-2 items-center justify-center left-0 p-1 w-36 rounded-full pl-3 pr-3 pt-1 pb-1 hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
              >
                {" "}
                <FiArrowLeft /> Go Back{" "}
              </button>
              <Project details={true} projectID={project.projectID} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Projects;
