import React, { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { app } from "../firebase";
import Loader from "./minis/Loader";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
const PriceList = () => {
  const [pdfAsset, setPdfAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const storage = getStorage(app);
  const storageRef = ref(storage, `pdf/pricelist.pdf`);

  let progress = 0;

  const loggedInUser = useSelector(state => (state.loggedInUser))
  var isAdmin = false;
  if(loggedInUser){
    isAdmin = JSON.parse(loggedInUser)?.isAdmin;}
  

  const uploadPdf = (e) => {
    setIsLoading(true);
    const pdfFile = e.target.files[0];
    const uploadTask = uploadBytesResumable(storageRef, pdfFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log(error);
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setPdfAsset(downloadURL);
          setIsLoading(false);
        });
      }
    );
  };
  const deleteImage = () => {
    setIsLoading(true);
    const deleteRef = ref(storage, pdfAsset);
    deleteObject(deleteRef).then(() => {
      setPdfAsset(null);
      setIsLoading(false);
    });
  };


  useEffect(() => {
    getDownloadURL(storageRef).then((downloadURL) =>{
        setPdfAsset(downloadURL);
    })
  }, [])
  return (
    <div className=" w-screen">
      <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-500 w-full h-screen cursor-pointer rounded-lg">
        {isLoading ? (
          <CircularProgress value={progress} />
        ) : (
          <div>
            {!pdfAsset && isAdmin ? (
              <>
                <label className="w-full h-screen flex flex-col items-center justify-center cursor-pointer">
                  <div className=" gap-2 w-full h-full flex flex-col items-center justify-center text-gray-500 hover:text-gray-700">
                    <MdCloudUpload className=" text-3xl " />
                    <p>Click here to upload the Pdf.</p>
                  </div>
                  <input
                    type="file"
                    name="uploadpdf"
                    id="uploadpdf"
                    accept="pdf/*"
                    onChange={uploadPdf}
                    className="w-0 h-o"
                  />
                </label>
              </>
            ) : (
              <>
                <div className=" h-screen w-screen">
                  <iframe
                    src={pdfAsset}
                    className=" w-full h-full"
                  />
                  { isAdmin && (<button
                    type="button"
                    className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md duration-150 transition-all ease-in-out"
                    onClick={deleteImage}
                  >
                    <MdDelete className="text-white" />
                  </button>)}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceList;