import React, { useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  getFirestore,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { app } from "../firebase";
import { Divider } from "@mui/material";


const db = getFirestore(app);

const Order = ({ projectID, order, status, refreshPage  }) => {
  const loggedInUser = useSelector(state => (state.loggedInUser))
  
  var isAdmin = false;
  if(loggedInUser){
    isAdmin = JSON.parse(loggedInUser)?.isAdmin;}
  
  const [isDetails, setIsDetails] = useState(false);
  const [exists, setExists] = useState(true);
  const preventEdit = (status > 1 && !isAdmin) || (status === 5);
  const [qty, setQty] = useState(order.quantity);

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const times = ["AM", "PM"];



  const toggleDetails = () => {
    setIsDetails(!isDetails);
  }

  const deleteOrder = (orderID) => {
    deleteDoc(doc(db, `orders/${projectID}/products`, orderID)).then(setExists(false));
    refreshPage();
  }

  const updatePlusOrder = (orderID) => {
    const orderRef = doc(db, `orders/${projectID}/products`, orderID);
    updateDoc(orderRef, {
      quantity: Number(qty + 1),
    }).then(setQty(qty+1));
    refreshPage();
  }

  const updateMinusOrder = (orderID) => {
    if(qty == 1){
        deleteDoc(doc(db, `orders/${projectID}/products`, orderID)).then(setExists(false));
    } else {
    const orderRef = doc(db,`orders/${projectID}/products`, orderID);
    updateDoc(orderRef, {
      quantity: Number(qty - 1),
    }).then(setQty(qty-1));}
    refreshPage();
  }

  return (
    <div className="w-full bg-cust-white items-center rounded-xl mt-1 mb-1 p-1" >
    { exists ? <>
    <div className=" flex flex-col">
      <div className=" w-full flex justify-around items-center rounded-xl p-1 ">
        {/* <span className="hidden md:block  w-40">
          {order.date}-{monthNames[order.month - 1]}-{order.year}  <br /> {(order.hour)%12}: {order.minute} {times[Math.floor((order.hour)/12)]}
        </span> */}
        <span className=" flex flex-col w-40"> <span>{order.fabricId}</span><span>({order.category})</span></span>
        { (isAdmin && order.metrics == 'Inches' ) ? <span className="hidden md:block  w-40"> {(2.54*(order.width)).toFixed(2)} CM</span> : 
        <span className="hidden md:block  w-40"> {order.width} {order.metrics}</span> }
        { (isAdmin && order.metrics == 'Inches' ) ? <span className="hidden md:block  w-40"> {(2.54*(order.length)).toFixed(2)} CM</span> :
        <span className="hidden md:block  w-40"> {order.length} {order.metrics}</span>}
        <span className="hidden md:block  w-40"> {order.operation}</span>
        <span className=" w-40">
            
        { !preventEdit && <button
                        onClick={() => updateMinusOrder(order.orderID)}
                        className=" p-1 rounded-full hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
                      > <FiMinus />
                      </button>}  {qty}  
                      { !preventEdit && <button
                        onClick={() => updatePlusOrder(order.orderID)}
                        className=" p-1 rounded-full  hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
                      > <FiPlus />
                      </button>} </span>
        <span className="hidden md:block  w-40"> {Number(order.totalPrice*qty).toFixed(2)}</span>
        <span className=" w-40"> 
                    <span className=" flex justify-center gap-2">
                      {" "}
                      <button
                        onClick={() => toggleDetails()}
                        className=" p-1 rounded-full hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
                      >
                       {isDetails ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </button>
                      { !preventEdit && <button
                        onClick={() => deleteOrder(order.orderID)}
                        className=" p-1 rounded-full  hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
                      >
                        {" "}
                        <MdDelete />{" "}
                      </button>}
                    </span> </span>
      </div>
      </div>
      { isDetails && <div className=" grid grid-cols-1 m-2 gap-2">

        {/* Show only in mobile  */}

        { (isAdmin && order.metrics == 'Inches' ) ? <span className="block md:hidden ">Width: {(2.54*(order.width)).toFixed(2)} CM</span> : 
        <span className="block md:hidden ">Width: {order.width} {order.metrics}</span> }
        { (isAdmin && order.metrics == 'Inches' ) ? <span className="block md:hidden ">Length: {(2.54*(order.length)).toFixed(2)} CM</span> :
        <span className="block md:hidden ">Length: {order.length} {order.metrics}</span>}
        <div className="flex w-full p-2 justify-between">
           <span>Area(chargeable):</span> <span className=" flex text-lg text-cust-red "> {order.area} Sqft </span>
        </div>
        <div className=" w-full p-2 flex justify-between">
               <span>Price of {order.fabricId} :</span><span className=" text-lg text-cust-red ">{"\u20B9"} {order.fabricPrice}/sqft</span> 
            </div>
        <div className=" w-full p-2 flex justify-between">
               <span>Operation: {order.operation} </span><span className=" text-lg text-cust-red ">{"\u20B9"} {order.operationPrice}/sqft</span> 
            </div>
        { order.addOn && order.addOn != "" && 
        <div className=" w-full p-2 flex justify-between">
               <span>Add on: {order.addOn} </span><span className=" text-lg text-cust-red ">{"\u20B9"} {order.addOnPrice}/pc.</span> 
            </div>}
        { order.motor && order.motor != "" && 
        <div className=" w-full p-2 flex justify-between">
               <span>Motor: {order.motor} </span><span className=" text-lg text-cust-red ">{"\u20B9"} {order.motorPrice}/pc</span> 
            </div>}
        { order.remote && order.remote != "" && 
        <div className=" w-full p-2 flex justify-between">
               <span>Remote: {order.remote} </span><span className=" text-lg text-cust-red ">{"\u20B9"} {order.remotePrice}/pc</span> 
            </div>}
        <Divider />
        <div className=" w-full p-2 flex justify-between">
               <span>Subtotal (Excl. of GST): </span><span className=" text-base md:text-lg text-cust-red ">{"\u20B9"} {(order.totalPrice)}</span>
               </div>
        <Divider />
        { order.switch && order.switch != "" && <div className=" w-full p-2 text-left"> Switch: {order.switch} </div>}
        { order.ball && order.ball != "" && <div className=" w-full p-2 text-left"> Ball: {order.ball} </div>}
        { order.ladder && order.ladder != "" && <div className=" w-full p-2 text-left"> Ladder: {order.ladder} </div>}
        { order.operatingSide && <div className=" w-full p-2 text-left"> Side of Operation: {order.operatingSide} </div>}
        { order.motorType && <div className=" w-full p-2 text-left"> Mechanism: {order.motorType} </div>}
        { order.system && order.system != "" && <div className=" w-full p-2 text-left"> System: {order.system} </div>}
        { order.medium && order.medium != "" && <div className=" w-full p-2 text-left"> Medium: {order.medium} </div>}
      </div>}
    </> : <> Product {order.category} was deleted.</> }
    </div>
  );
};

export default Order;
