import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../firebase";

import { doc, getDoc, getFirestore } from "firebase/firestore";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "./minis/Loader";
import { Container, FormControl, TextField } from "@mui/material";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState("Sign In");

  const auth = getAuth();
  const dispatch = useDispatch();

  const db = getFirestore(app);

  const getUser = async () => {
    const userRef = doc(db, "users", email);
    const user = (await getDoc(userRef)).data();
    // console.log(user);
    return user;
  };
  const signIn = () => {
    if (email == "" || password == "") {
      setAlert("All fields are required!");
    } else {
      setIsLoading(true);
      getUser()
        .then((user) => {
          if (user.access) {
            signInWithEmail(user);
          } else {
            setAlert("Application pending!");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setAlert("User not found!");
          setIsLoading(false);
        });
    }
  };
  const signInWithEmail = (user) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((payload) => {
        // console.log(payload);
        // Signed in
        dispatch({
          type: "LOGIN",
          user: JSON.stringify(user),
        });
        localStorage.setItem("loggedInUser", JSON.stringify(user));
        setAlert("Login Successful!");
        setIsLoading(false);
        // ...
      })
      .catch((error) => {
        setAlert(error.message);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="flex h-screen justify-center items-center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="card flex-col h-fit p-4 mt-16 mb-16 items-center bg-cust-white rounded-sm "
        >
          <p className="text-cust-red p-3 m-6 "> {alert} </p>
          <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
            <span className=" w-20 right-0  h-full text-white ">Email</span>
            <input
              className=" h-full text-sm p-2 text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none "
              type="email"
              name="email"
              placeholder="example@mail.com"
              required
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
            <span className=" w-20 right-0  h-full text-white ">Password</span>
            <input
              className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none "
              type="password"
              name="password"
              id="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </div>
          <Link to="./forgot" className="text-cust-red underline p-3 m-6 ">
            Forgot Password?
          </Link>
          <div className="flex justify-around m-3 ">
            {isLoading ? (
              <Loader />
            ) : (
              <motion.button
                id="button"
                type="submit"
                onClick={signIn}
                whileTap={{ translateX: 4 }}
                className="  cursor-pointer border border-cust-red w-32 ml-4 mr-4 shadow-md hover:shadow-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
              >
                LOGIN
              </motion.button>
            )}
          </div>
          <Link to="./signup" className="text-cust-red underline p-3 m-6 ">
            Don't have an account?
          </Link>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
