import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className=" flex flex-col min-h-screen items-center justify-around">
      <div className="card capitalize rounded-sm w-2/3 text-left flex flex-col md:flex-row justify-around h-fit p-5 m-10">
        <div className=" flex flex-col items-center m-4 ">
            
        <strong className=" text-xl uppercase font-belleza text-cust-red"> Head Office </strong>
        <div>
          <p>Louverline Blinds Pvt Ltd.</p>
          No: 43/2, singasnadra, electronic city post, <br />
          bangalore - 560100 <br /> Karnataka, India <br /> <br />
        <strong className=" text-md text-left text-cust-khaki"> Mr. Dhruv Bhinde </strong> <br />
        Tel: +91-9686440087 <br />
        Fax: +91 80 22109102 <br />
        <Link className=" lowercase underline" to="mailto:dhruv@louverlineblinds.com">dhruv@louverlineblinds.com</Link>
        </div>
        </div>

        <div className=" flex flex-col items-center m-4 ">
            
        <strong className=" text-xl uppercase font-belleza text-cust-red"> Sales Office </strong>
        <div>
          <p>Louverline Blinds Pvt Ltd.</p>
          Ananthaa, #7-2, 3rd Main Road, Jayanagar 6th Block,<br />
          bangalore - 560070 <br /> Karnataka, India <br /> <br />
        <strong className=" text-md text-left text-cust-khaki"> Mr. Nagaraj BR </strong> <br />
        Tel: +91- 9986064798 <br />
        <Link className=" lowercase underline" to="mailto:nagaraj@louverlineblinds.com">nagaraj@louverlineblinds.com</Link>
        </div>
        </div>
      </div>
      {/* <div className="card w-2/3 p-5 m-10">
        <strong className=" text-xl text-cust-red"> Sales & Enquiry </strong>
        <div className=" flex flex-col md:flex-row justify-around mt-2">
            <div className=" flex flex-col items-center m-4 ">
        <strong className=" text-lg text-left text-cust-red">Delhi & NCR</strong> <br />
        <strong className=" text-md text-left text-cust-khaki"> Mr. Verma GP </strong>
        Ph: +91-9899120089 <br />
        <Link className=" underline" to="mailto:vermagp@louverlineblinds.com">vermagp@louverlineblinds.com</Link>
            </div>
            <div className=" flex flex-col items-center m-4 ">
        <strong className=" text-lg text-left text-cust-red"> Gujarat</strong> <br />
        <strong className=" text-md text-left text-cust-khaki">Mr. Nitesh Sharma</strong>
        Ph: +919986064793<br />
        <Link className=" underline" to="mailto:sales.gujarat@louverlineblinds.com">sales.gujarat@louverlineblinds.com</Link>
            </div>
        </div>
            <div className=" flex flex-col items-center m-4 ">
        <strong className=" text-lg text-left text-cust-red">Mumbai, Associate Office</strong> <br />
        <strong className=" text-md text-left text-cust-khaki">Mr. Manoj Mukatiwala</strong>
        Ph: +91-9821273510<br />
        <Link className=" underline" to="mailto:max3inc@gmail.com">max3inc@gmail.com</Link>
            </div>
        
      </div>
      <div className=' m-4'>
        
      <strong className=" text-xl text-cust-red"> For Dealership Inquiries </strong><br />
        <Link className=" underline" to="mailto:dhruv@louverlineblinds.com">dhruv@louverlineblinds.com</Link>
      </div> */}
    </div>
  );
};

export default Contact;
