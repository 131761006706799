import React, { useState } from "react";
import logo2 from "./images/logo1.png";
import { useLocation, Link, useNavigate } from "react-router-dom";

import {
  MdLogout,
  MdHome,
  MdPriceCheck,
  MdPassword,
  MdOutlineAddBox,
  MdWindow,
  MdClose,
} from "react-icons/md";
import { FiArrowRight, FiBox, FiMenu, FiPhone, FiUser } from "react-icons/fi";
import { HiUsers } from "react-icons/hi";
import { RxBox, RxCross1 } from "react-icons/rx";

import { motion } from "framer-motion";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillLinkedin,
  AiFillBoxPlot,
  AiFillProfile,
} from "react-icons/ai";

import { getAuth, signOut } from "firebase/auth";

import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const loggedInUser = useSelector((state) => state.loggedInUser);

  var isAdmin = false;
  if (loggedInUser) {
    isAdmin = JSON.parse(loggedInUser)?.isAdmin;
  }

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isMenu, setIsMenu] = useState(false);
  const [showTopLabel, setShowTopLabel] = useState(true)
  const [orderQ, setOrderQ] = useState();
  const avatarClick = () => {
    setIsMenu(!isMenu);
  };

  const breadMenuStyle ="cursor-pointer relative after:origin-left after:scale-x-0 flex h-full after:top-full after:left-0 pl-5 w-full gap-1 py-2 after:z-50 after:absolute  after:transition-all after:duration-200 after:bg-cust-khaki after:h-0.5 after:w-full hover:after:scale-x-100 transition-all duration-200 items-center capitalize";

  const logOut = () => {
    const auth = getAuth();
    setIsMenu(false);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch({
          type: "LOGOUT",
        });
        localStorage.clear();
        navigate("./");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <>
    { showTopLabel && <div className=" flex justify-center gap-3 items-center p-1 w-full bg-cust-khaki text-cust-white">
    <a target="_blank" title="Watch the tutorial" className=' text-inherit hover:text-blue-700 underline' onClick={ () => setShowTopLabel(false) } href="https://drive.google.com/file/d/1eQjIYZhjBoEJUsRJBDxfzNSRkqEguDP3/view?usp=sharing">Click here to watch the tutorial on how to use My Louverline</a>
    <span title="Close banner" onClick={ () => setShowTopLabel(false) } className=" hover:text-blue-700 cursor-pointer"><MdClose /></span>
    </div>}
      <header className="App-header relative w-screen z-30 flex justify-between align-middle text-md p-3 bg-cust-black">
        
        {/* desktop */}
        <div className="hidden md:flex w-full h-full justify-between ">
          <div
            onClick={() => setIsMenu(false)}
            className="flex items-center gap-1 cursor-pointer"
          >
            <Link to={"./"}>
              <img
                src={logo2}
                className="h-10 object-cover"
                alt="My Louverline"
              />
            </Link>
            <div className=" hidden md:flex text-cust-white text-3xl m-2 justify-evenly w-40">
              <Link
                className=" hover:text-cust-khaki "
                to={"https://www.instagram.com/louverlineblindsofficial/"}
              >
                <AiFillInstagram />
              </Link>
              <Link
                className=" hover:text-cust-khaki "
                to={"https://www.facebook.com/louverlineblindsofficial"}
              >
                <AiFillFacebook />
              </Link>
              <Link
                className=" hover:text-cust-khaki "
                to={"https://www.linkedin.com/company/louverlineblindsofficial"}
              >
                <AiFillLinkedin />
              </Link>
            </div>
            { loggedInUser && (
              <>
              <div className=" flex items-center">
                <input
                  type="text"
                  className=" focus:border-cust-khaki text-sm border-t-0 border-x-0 bg-cust-black border-cust-white w-fit focus:ring-0 outline-none"
                  name="search"
                  placeholder="Enter Order ID"
                  onChange={(e) => setOrderQ(e.target.value)}
                />
              </div>
              { orderQ && <Link
                className=" flex justify-center rounded-md items-center text-black h-full px-2 py-1 bg-cust-white "
                to={`http://mylouverline.com/order/${orderQ}`}
              >
                Go
              </Link>}
              </>
            ) }
          </div>

          <div className="flex items-center justify-between">
            <motion.ul
              initial={{ opacity: 0, x: 200 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 200 }}
              className="flex items-center gap-3"
            >
              <Link
                to="./"
                className={`cursor-pointer relative after:origin-left after:scale-x-0 flex h-full after:top-full after:left-0 pl-5 pr-5 pt-2 pb-2 after:z-50 after:absolute ${pathname === '/' ? 'text-cust-khaki' : 'text-white'} after:transition-all after:duration-200 after:bg-cust-khaki after:h-0.5 after:w-full hover:after:scale-x-100 flex-col transition-all duration-200 items-center capitalize`}
              >
                Home
              </Link>
              {loggedInUser && (
                <Link
                  to={"./orders"}
                  className={`cursor-pointer relative after:origin-left after:scale-x-0 flex h-full after:top-full after:left-0 pl-5 pr-5 pt-2 pb-2 after:z-50 after:absolute ${pathname === '/orders' ? 'text-cust-khaki' : 'text-white'} after:transition-all after:duration-200 after:bg-cust-khaki after:h-0.5 after:w-full hover:after:scale-x-100 flex-col transition-all duration-200 items-center capitalize`}
                >
                  Orders
                </Link>
              )}
              {loggedInUser && (
                <Link
                  to={"./pricelist"}
                  className={`cursor-pointer relative after:origin-left after:scale-x-0 flex h-full after:top-full after:left-0 pl-5 pr-5 pt-2 pb-2 after:z-50 after:absolute ${pathname === '/pricelist' ? 'text-cust-khaki' : 'text-white'} after:transition-all after:duration-200 after:bg-cust-khaki after:h-0.5 after:w-full hover:after:scale-x-100 flex-col transition-all duration-200 items-center capitalize`}
                  href="./pricelist"
                >
                  Price list
                </Link>
              )}
              <Link
                to={"./contactus"}
                className={`cursor-pointer relative after:origin-left after:scale-x-0 flex h-full after:top-full after:left-0 pl-5 pr-5 pt-2 pb-2 after:z-50 after:absolute ${pathname === '/contactus' ? 'text-cust-khaki' : 'text-white'} after:transition-all after:duration-200 after:bg-cust-khaki after:h-0.5 after:w-full hover:after:scale-x-100 flex-col transition-all duration-200 items-center capitalize`}
              >
                Contact
              </Link>
            </motion.ul>

            <div className="relative">
              {loggedInUser && (
                <motion.p
                  whileHover={{ scale: 1.03 }}
                  onClick={avatarClick}
                  className=" cursor-pointer ml-3 login-button flex items-centre shadow-lg p-3 hover:bg-cust-khaki text-cust-khaki hover:text-cust-white rounded-full"
                >
                  {isMenu ? <RxCross1 /> : <FiMenu />}
                </motion.p>
              )}

              {isMenu && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.6 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.6 }}
                  className="w-fit  gap-2 shadow-xl rounded-lg flex flex-col absolute px-4 py-2 bg-cust-black right-2 top-16"
                >
                  {loggedInUser && (
                    <Link to={`/profile`}>
                      <p
                        onClick={() => setIsMenu(false)}
                        className={breadMenuStyle}
                      >
                        <FiUser />
                        My Profile
                      </p>
                    </Link>
                  )}
                  {isAdmin && (
                    <Link to={"./users"}>
                      <p
                        onClick={() => setIsMenu(false)}
                        className={breadMenuStyle}
                      >
                        <HiUsers />
                        Users{" "}
                      </p>
                    </Link>
                  )}

                  {loggedInUser && (
                    <Link to={"./forgot"}>
                      <p
                        onClick={() => setIsMenu(false)}
                        className={breadMenuStyle}
                      >
                        <MdPassword />
                        Change Password{" "}
                      </p>
                    </Link>
                  )}
                  {loggedInUser && (
                    <p
                      className="px-2 py-2 flex items-center gap-2 cursor-pointer hover:bg-cust-khaki hover:text-headingColor transition-all duration-100 ease-in-out text-textColor bg-cust-red shadow-md"
                      onClick={logOut}
                    >
                      <MdLogout />
                      Log out{" "}
                    </p>
                  )}
                </motion.div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Screen */}
        <div className="flex md:hidden w-full h-full justify-between ">
          <div className=" flex">
            <Link to={"./"}>
              <div
                onClick={() => setIsMenu(false)}
                className="flex items-center gap-1 cursor-pointer"
              >
                <img src={logo2} className="h-16 object-cover" alt="logo" />
              </div>
            </Link>
            {/* { loggedInUser && (
              <>
              <div className=" flex items-center">
                <input
                  type="text"
                  className=" focus:border-cust-khaki border-t-0 border-x-0 bg-cust-black border-cust-white text-sm focus:ring-0 outline-none"
                  name="search"
                  placeholder="Enter Order ID..."
                  onChange={(e) => setOrderQ(e.target.value)}
                />
              </div>
              { orderQ && <Link
                className=" flex justify-center rounded-md items-center text-black h-full px-2 py-1 bg-cust-white "
                to={`http://mylouverline.com/order/${orderQ}`}
              >
                Go
              </Link>}
              </>
            ) } */}
          </div>
          <div className="relative">
            <motion.a
              whileHover={{ scale: 1.03 }}
              onClick={avatarClick}
              className=" cursor-pointer ml-3 login-button flex items-centre shadow-lg p-3 hover:bg-cust-khaki text-cust-khaki hover:text-cust-white rounded-full"
            >
              {isMenu ? <RxCross1 /> : <FiMenu />}
            </motion.a>

            {isMenu && (
              <motion.div
                initial={{ opacity: 0, scale: 0.6 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.6 }}
                className="w-40  gap-2 shadow-xl rounded-lg flex flex-col absolute px-4 py-2 bg-cust-black right-2 top-16"
              >
                <Link to={"./"}>
                  <p
                    onClick={() => setIsMenu(false)}
                    className={breadMenuStyle}
                  >
                    <MdHome />
                    Home{" "}
                  </p>
                </Link>
                {loggedInUser && (
                  <Link to={`/profile`}>
                    <p
                      onClick={() => setIsMenu(false)}
                      className={breadMenuStyle}
                    >
                      <FiUser />
                      My Profile
                    </p>
                  </Link>
                )}
                {loggedInUser && (
                  <Link to={"./orders"}>
                    <p
                      onClick={() => setIsMenu(false)}
                      className={breadMenuStyle}
                    >
                      <MdWindow />
                      Orders{" "}
                    </p>
                  </Link>
                )}
                {isAdmin && (
                  <Link to={"./users"}>
                    <p
                      onClick={() => setIsMenu(false)}
                      className={breadMenuStyle}
                    >
                      <HiUsers />
                      Users{" "}
                    </p>
                  </Link>
                )}
                <Link to={"./contactus"}>
                  <p
                    onClick={() => setIsMenu(false)}
                    className={breadMenuStyle}
                  >
                    <FiPhone />
                    Contact{" "}
                  </p>
                </Link>
                {loggedInUser && (
                  <Link to={"./forgot"}>
                    <p
                      onClick={() => setIsMenu(false)}
                      className={breadMenuStyle}
                    >
                      <MdPassword />
                      Change Password{" "}
                    </p>
                  </Link>
                )}
                {loggedInUser && (
                  <Link to={"./pricelist"}>
                    <p
                      onClick={() => setIsMenu(false)}
                      className={breadMenuStyle}
                    >
                      <MdPriceCheck />
                      Price list{" "}
                    </p>
                  </Link>
                )}
                {loggedInUser && (
                  <p
                    className="px-2 py-2 flex items-center gap-2 cursor-pointer hover:bg-cust-khaki hover:text-headingColor transition-all duration-100 ease-in-out text-textColor bg-cust-red shadow-md"
                    onClick={logOut}
                  >
                    <MdLogout />
                    Log out{" "}
                  </p>
                )}
              </motion.div>
            )}
          </div>
        </div>

        {/* <a href="/">
        {" "}
        <img src={logo2} alt="" className=" h-10" />
      </a>
      <div className="menu flex h-10 justify-between align-middle">
        <a
          className={
            pathname === "/"
              ? "pl-5 pr-5 pt-2 pb-2 text-cust-khaki hover:border-b-2 transition duration-300 border-cust-khaki"
              : "pl-5 pr-5 pt-2 pb-2 hover:border-b-2 transition duration-300 ease-in-out border-cust-khaki "
          }
          href="./"
        >
          Home
        </a>
        <a
          className={
            pathname === "/contactus"
              ? "pl-5 pr-5 pt-2 pb-2 text-cust-khaki hover:border-b-2 transition duration-300 border-cust-khaki"
              : "pl-5 pr-5 pt-2 pb-2 hover:border-b-2 transition duration-300 ease-in-out border-cust-khaki "
          }
          href="./contactus"
        >
          Contact
        </a>
        <motion.a
          whileHover={{ scale: 1.03 }}
          onClick={avatarClick}
          className=" cursor-pointer ml-3 login-button hidden md:flex items-centre shadow-lg p-3 hover:bg-cust-khaki text-cust-khaki hover:text-cust-white rounded-full"
        >
          <FaUserCircle />{" "}
        </motion.a>
      </div> */}
      </header>
    </>
  );
};

export default Header;
