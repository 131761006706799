import React from "react";
import { ScaleLoader } from "react-spinners";
import animatedlogo from "../images/Louver.gif";
import { LinearProgress } from "@mui/material";
const Loader = ({ type, progress }) => {
  return (
    <div
      role="status"
      className={
        type === "main"
          ? " flex flex-col h-full w-full bg-white text-gray-400 justify-center items-center"
          : " flex text-cust-red flex-col h-full w-full justify-center items-center"
      }
    >
      {type === "main" ? (
        <>
          <img src={animatedlogo} alt="" /> <br />
          <span className=" text-cust-red">
            <LinearProgress
              className=" w-80"
              color="inherit"
              variant="determinate"
              value={progress}
            />
          </span>
          Loading a seamless experience. <br />
          Please wait...
        </>
      ) : (
        <ScaleLoader color="#AF2925" />
      )}
    </div>
  );
};

export default Loader;
