import { legacy_createStore as createStore } from "redux";
import { fetchUser } from '../utils/fetchLocalStorage'

const userInfo = fetchUser();

const initialState = {
    users: [],
    loggedInUser: userInfo,
    products: [],
    outlookData: [],
    deliteData: [],
    newaluminiumData: [],
    symphonyData: [],
    rollerFabricData: [],
    woodData: [],
    orders: [],
    pricelist: [],
}

const reducer = (state = initialState, action) =>{
    switch(action.type){
        case 'SIGNUP':
            return {
                ...state,
                users : [...state.users, action.payload]
            }
        case 'LOGIN':
            return {
                ...state,
                loggedInUser: action.user
            }
        case 'LOGOUT':
            return {
                    ...state,
                    loggedInUser: null
            }
        case "SET_PRODUCTS":
            return {
                    ...state,
                    products: action.mergedData
                };
                
        case "SET_OUTLOOK":
            return {
                    ...state,
                    outlookData: action.outlookData
                };
                
        case "SET_ROLLER":
            return {
                    ...state,
                    rollerFabricData: action.rollerData
                };
                
        case "SET_DELITE":
            return {
                    ...state,
                    deliteData: action.deliteData
                };
                
        case "SET_SYMPHONY":
            return {
                    ...state,
                    symphonyData: action.symphonyData
                };
                
        case "SET_WOOD":
            return {
                    ...state,
                    woodData: action.woodData
                };
                
        case "SET_NEWALUMINIUM":
            return {
                    ...state,
                    newaluminiumData: action.newaluminiumData
                };
                
                 
        case "SET_PRICELIST":
            return {
                    ...state,
                    pricelist: action.pricelist
                };
                
        case "SET_ORDERS":
            return {
                    ...state,
                    orders: action.orders
                };
                
                
        default:
            return state;
    }
}

export default createStore(reducer)