import React from "react";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import { CgOrganisation } from "react-icons/cg";

const UserList = ({ item, toggleAccess, deletePerson }) => {
  return (
    <div className=" hover:shadow w-full rounded-sm flex justify-between p-2 bg-white ">
    <Link className=" w-full flex justify-around cursor-pointer" to={`/user/${item.email}`}>
      <div className="ml-0 md:ml-2 w-52 md:w-60 flex flex-col">
        <span className=" text-cust-red font-belleza capitalize text-left text-lg md:text-2xl">
          {" "}
          {item.name}{" "}
        </span>
        <span className=" text-left text-cust-black">
          {" "}
          <CgOrganisation /> <span className=" text-base capitalize"> {item.orgName}</span>
        </span>
      </div>
      <span className=" w-80 hidden md:flex flex-col items-left justify-between">
        <span className=" lowercase text-left">
            {item.email}
        </span>
        <span className=" text-left">{item.phone} </span>
      </span>
    </Link>
      {/* buttons */}

      <div className=" mr-8 p-4">
      <span className=" mr-2 flex justify-center gap-2 items-center">
        {item.access ? (
          <button
            onClick={() => toggleAccess(item)}
            className="cursor-pointer border border-cust-red shadow-md h-fit hover:shadow-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white p-2 rounded-full"
          >
            <RxCross1 />
          </button>
        ) : (
          <button
            onClick={() => toggleAccess(item)}
            className="cursor-pointer border border-green-500 shadow-md h-fit hover:shadow-green-500 text-green-500 hover:bg-green-500 hover:text-cust-white p-2 rounded-full"
          >
            <TiTick />
          </button>
        )}
        {/* <button
          onClick={() => deletePerson(item)}
          className="cursor-pointer border border-cust-red shadow-md h-fit hover:shadow-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white p-2 rounded-full"
        >
          <MdDelete />
        </button> */}
      </span>
      </div>

    </div>
  );
};

export default UserList;
