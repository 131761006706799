import React, { useEffect, useState } from "react";
import {
  where,
  query,
  collection,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { app } from "../firebase";
import { useSelector } from "react-redux";
import { admins } from "./utils/admins";

const db = getFirestore(app);

const ProjectListItem = (props) => {
  const [orders, setOrders] = useState([]);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const project = props.project;
  const projectID = project.projectID;
  const [status, setStatus] = useState(props.project.status);
  const [projectTime, setProjectTime] = useState();
  const [projectDate, setProjectDate] = useState();
  const [isDetails, setIsDetails] = useState(false);
  const [exists, setExists] = useState(true);
  const [userDet, setUserDet] = useState();
  const loggedInUser = useSelector(state => (state.loggedInUser))
  
  var isAdmin = false;
  if(loggedInUser){
    isAdmin = JSON.parse(loggedInUser)?.isAdmin;}
  

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const orderSts = ['Incomplete', 'Declined', 'Under Review', 'Confirmed', 'In Production', 'Dispatched'];


  const getUser = async () => {
    const projects = await getDocs(
      query(collection(db, "users"), where("email", "==", project.user))
    );
    projects.docs.map((doc) => setUserDet(doc.data()));
  };
  useEffect(() => {
    getUser();
    if (props.details) {
      setIsDetails(true);
    }
    var someDate = new Date();
    someDate.setTime(Number(project.time));
    setProjectTime(formatAMPM(someDate));
    setProjectDate(`${someDate.getDate()}-${monthNames[someDate.getMonth()]}-${someDate.getFullYear()}`)
  }, []);
  const handleClick = () => {
    if (!props.details) {
      props.clickFn(project);
    }
  };

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  return (
    <div className={" w-full text-sm md:text-md items-center  justify-center rounded-md"}>
      {exists ? (
        <>
        {
              <div
                onClick={handleClick}
                className=" hover:shadow flex justify-around items-center cursor-pointer rounded-sm bg-white p-1 "
              >
                <span className=" w-40"> {project.projectName} </span>
                {isAdmin && userDet && (
                  <span className=" capitalize w-40"> {userDet.name} </span>
                )}
                <span className=" w-40">
          {projectDate}<br /> {projectTime}
                </span>
                <span className={ project.status === 2 ? " capitalize rounded-full text-xs bg-yellow-300 w-40 px-2 py-1" : ( project.status === 5 ? " capitalize text-xs rounded-full bg-green-300 w-40 px-2 py-1" :( project.status > 2 ?  " capitalize text-xs bg-blue-300 w-40 rounded-full px-2 py-1" : " capitalize text-xs bg-red-300 w-40 rounded-full px-2 py-1") ) }> {orderSts[project.status]} </span>
              </div>
    
     }
        </>
      ) : (
        <> Order {project.projectName} deleted </>
      )}
    </div>
  );
};

export default ProjectListItem;
