import React from "react";
import logo from "./images/logo1.png";
import { Link } from "react-router-dom";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillLinkedin,
} from "react-icons/ai";

const Footer = () => {
  return (
    <div className=" flex flex-col justify-center items-center bottom-0 text-white w-full p-3 bg-cust-black">
        <div className=" flex w-fit flex-col md:flex-row gap-2 items-center m-4">
        <img src={logo} alt="" className="h-20" />
        <div className=" flex text-cust-white text-3xl m-2 justify-evenly w-40">
          <Link className=" hover:text-cust-khaki " to={"https://www.instagram.com/louverlineblindsofficial/"}>
            <AiFillInstagram />
          </Link>
          <Link className=" hover:text-cust-khaki " to={"https://www.facebook.com/louverlineblindsofficial"}>
            <AiFillFacebook />
          </Link>
          <Link className=" hover:text-cust-khaki " to={"https://www.linkedin.com/company/louverlineblindsofficial"}>
            <AiFillLinkedin />
          </Link>
        </div>
        </div>
        <p className=" mt-4 text-xs">Copyright © {new Date().getFullYear()} Louverline Blinds Pvt. Ltd. | All Rights Reserved</p>
    </div>
  );
};

export default Footer;
