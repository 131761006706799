import React from "react";
import { FadeLoader } from "react-spinners";
const FullLoader = () => {
  return (
    <div className=" absolute w-full h-full flex justify-center items-center z-40 bg-black/25 opacity-30">
        <FadeLoader color="#AF2925" />
    </div>
  );
};

export default FullLoader;
