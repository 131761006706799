import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import Announcements from "./Announcements";
import Loader from "./minis/Loader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const MainContainer = () => {
  const [input, setInput] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedCat, setSelectedCat] = useState('');
  const [ids, setIds] = useState([]);
  const [qty, setQty] = useState(0);
  const [res, setRes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const roller = useSelector((state) => state.rollerFabricData);
  const outlookData = useSelector((state) => state.outlookData);
  const newaluminium = useSelector((state) => state.newaluminiumData);
  const symphony = useSelector((state) => state.symphonyData);
  const delite = useSelector((state) => state.deliteData);
  const wood = useSelector((state) => state.woodData);
  const categories = [
    "roller",
    "delite",
    "symphony",
    "outlook",
    "wood",
    "newaluminium",
  ];
  const [mids, setMids] = useState([]);

  // Fetch Category Data

  const fetchSheetData = async (cat) => {
    setIsLoading(true);
    setSelectedCat(cat);
    switch (cat) {
      case "roller":
        if (roller.length < 1) {
          try {
            const rollerFabricData = await axios.get(
              process.env.REACT_APP_ROLLER_FABRIC_DATA
            );
            dispatch({
              type: "SET_ROLLER",
              rollerData: rollerFabricData.data.data,
            });
            setProducts(rollerFabricData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(roller);
        }
        setIsLoading(false);
        break;
      case "outlook":
        if (outlookData.length < 1) {
          try {
            const outlookData = await axios.get(
              process.env.REACT_APP_OUTLOOK_DATA
            );
            dispatch({
              type: "SET_OUTLOOK",
              outlookData: outlookData.data.data,
            });
            setProducts(outlookData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(outlookData);
        }
        setIsLoading(false);
        break;
      case "delite":
        if (delite.length < 1) {
          try {
            const deliteData = await axios.get(
              process.env.REACT_APP_DELITE_DATA
            );
            dispatch({
              type: "SET_DELITE",
              deliteData: deliteData.data.data,
            });
            setProducts(deliteData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(delite);
        }
        setIsLoading(false);
        break;
      case "symphony":
        if (symphony.length < 1) {
          try {
            const symphonyData = await axios.get(
              process.env.REACT_APP_SYMPHONY_DATA
            );
            dispatch({
              type: "SET_SYMPHONY",
              symphonyData: symphonyData.data.data,
            });
            setProducts(symphonyData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(symphony);
        }
        setIsLoading(false);
        break;
      case "wood":
        if (wood.length < 1) {
          try {
            const woodData = await axios.get(process.env.REACT_APP_WOOD_DATA);
            dispatch({
              type: "SET_WOOD",
              woodData: woodData.data.data,
            });
            setProducts(woodData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(wood);
        }
        setIsLoading(false);
        break;
      case "newaluminium":
        if (newaluminium.length < 1) {
          try {
            const newaluminiumData = await axios.get(
              process.env.REACT_APP_NEW_ALUMINIUM_DATA
            );
            dispatch({
              type: "SET_NEWALUMINIUM",
              newaluminiumData: newaluminiumData.data.data,
            });
            setProducts(newaluminiumData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(newaluminium);
        }
        setIsLoading(false);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (products.length > 0) {
      const uniqueIds = new Set(
        products.map((item) => item.prod_id.split(" ").join(""))
      );
      setIds(Array.from(uniqueIds));
      setIsLoading(false);
    }
  }, [products]);

  const searchFn = (ids, input) => {
    const regex = new RegExp(input.split("").join(".*"), "i");
    setMids(ids.filter((id) => regex.test(id)));
  };

  useEffect(() => {
    searchFn(ids, input);
  }, [input]);
  const inputFn = (value) => {
    setInput(value);
  };

  // Checks an shows the availability 
  const showRes = (e) => {
    e.preventDefault();
    var x = 0;
    var idhai = false;
    for (let i = 0; i < products.length; i++) {
      if (
        products[i].prod_id
          .split(" ")
          .join("")
          .toLowerCase() == input.toLowerCase()
      ) {
        x += Number(products[i].quantity);
        idhai = true;
      }
    }
    if (idhai) {
      var margin = 0.8;
      // Symphony 0.4
      if(selectedCat === 'symphony'){
        margin = 0.4;
      }
      if (qty < margin * x) {
        setRes("Available");
      } else {
        setRes("Unavailable");
      }
    } else {
      setRes("Product doesn't exist");
    }
  };
  return (
    <div>
      <div className="flex min-h-screen mt-8 flex-row justify-center items-center">
        {/* main section */}
        <div className=" flex absolute z-10 flex-col justify-center w-screen items-center">
          <form
            onSubmit={(e) => {
              showRes(e);
            }}
            className="card flex-col h-fit p-4 mt-28 md:mt-4 items-center bg-cust-white rounded-sm min-w-fit w-60 "
          >
            <h1 className="text-cust-red text-lg font-belleza">
              STOCK ENQUIRY
            </h1>
            <div className="flex-col flex justify-center w-fit items-center">
              <select
                onChange={(e) => fetchSheetData(e.target.value)}
                className=" border cursor-pointer hover:shadow focus:shadow flex border-cust-red focus:ring-0 focus:outline-none uppercase overflow-clip w-80 rounded-full m-2 text-center bg-transparent justify-between items-center "
                name="cat-sheet"
                id="cat-sheet"
              >
                <option value=""> Select Category </option>
                {categories.map((cat) => (
                  <option className="  uppercase" key={cat} value={cat}>
                    {" "}
                    {cat}{" "}
                  </option>
                ))}
              </select>
              {isLoading ? (
                <div className=" flex h-full w-full flex-col justify-center items-center">
                  <Loader />
                  <p> Fetching Data </p>
                </div>
              ) : (
                products.length > 0 && <>
                  <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                    <input
                      type="text"
                      className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none "
                      name="prod_id"
                      id="prod_id"
                      placeholder="Product"
                      value={input}
                      required={true}
                      onChange={(e) =>
                        setInput(
                          e.target.value
                            .split(" ")
                            .join("")
                            .toLowerCase()
                        )
                      }
                    />
                    <span className=" w-20 right-0 h-full text-white">
                      Code
                    </span>
                  </div>
                  <ul className={`relative max-h-0 z-10 bg-white `}>
                    {mids?.map(
                      (id) =>
                        input != "" &&
                        input != id && (
                          <li
                            key={id}
                            onClick={() => inputFn(id)}
                            className={`bg-transparent border cursor-pointer bg-white hover:bg-cust-red hover:text-white w-80 rounded-full m-1 text-center`}
                          >
                            {id}
                          </li>
                        )
                    )}
                  </ul>
                  <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                    <input
                      type="number"
                      className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none "
                      name="qty"
                      placeholder="Quantity"
                      required
                      id="qty"
                      onChange={(e) => setQty(e.target.value)}
                    />
                    <span className=" w-20 right-0 h-full text-white ">
                      in Sqft.
                    </span>
                  </div>
                  <div className="flex justify-around scroll m-3 ">
                    <motion.button
                      whileTap={{ translateX: 4 }}
                      type="submit"
                      className="  cursor-pointer border border-cust-red ml-4 mr-4  shadow-md hover:shadow-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
                    >
                      CHECK STOCK
                    </motion.button>
                  </div>
                  <p>{res}</p>
                </>
              )}
            </div>
          </form>
          <motion.div className="card md:hidden min-h-80 flex-col w-96  p-4 mt-16 mb-16 items-center bg-cust-white rounded-sm">
            <Announcements />
          </motion.div>
        </div>

        {/* side ANNOUNCEMENT section  */}
        <div className=" absolute z-20 h-screen right-0">
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            className="card hidden md:flex w-80 flex-col  p-4 bg-cust-white rounded-md"
          >
            <Announcements />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
