import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Loader from "./minis/Loader";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

import { useSelector } from 'react-redux';

const ForgotPassword = () => {

  const loggedInUser = useSelector(state => (state.loggedInUser))

  const [email, setEmail] = useState( loggedInUser ? JSON.parse(loggedInUser)?.email : '');
  const [alert, setAlert] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const auth = getAuth();
  const verify = () => {
    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        setAlert('Password reset email sent to your email address! Please check your inbox!')
        setIsLoading(false);
        setIsSent(true);
      })
      .catch((error) => {
        const errorMessage = error.message;
        setAlert(errorMessage);
        setIsLoading(false);
        // ..
      });
  };

  return (
    <div>
      <div className="flex justify-center min-h-screen items-center">
        <div className="card flex-col p-4 mb-16 h-fit items-center bg-cust-white rounded-lg w-fit ">
          { (loggedInUser) ? (
            <>
            <div>
              <p> Your Email address: {JSON.parse(loggedInUser)?.email}</p>
              <p className=" text-sm text-cust-red">A password reset email will be sent to your email address.</p>
            </div>
            { isSent ? <div> </div> : (
            <div className="flex justify-around m-3 ">{ isLoading ? <Loader /> : (
              <motion.button
                id="button"
                onClick={() => verify()}
                whileTap={{ translateX: 4 }}
                className="  cursor-pointer border border-cust-red w-32 ml-4 mr-4 shadow-md hover:shadow-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
              >
              SEND
              </motion.button>)}
            </div>) }
            <p> {alert} </p>
            </>
          ) : (
            <>
            <p className="text-cust-red p-3 m-6 ">Enter your email address</p>
            <div className=" block">
              <input
                onChange={(e) => setEmail(e.target.value)}
                className=" m-2 text-center w-80 rounded-full bg-transparent outline-none"
                type="email"
                name="email"
                placeholder="Email"
                id="email"
              />
            </div>
            <div></div>
            <p> {alert} </p>
            { isSent ? <div> </div> : (
            <div className="flex justify-around m-3 ">{ 
            isLoading ? <Loader /> : (
              <motion.button
                id="button"
                onClick={() => verify()}
                whileTap={{ translateX: 4 }}
                className="  cursor-pointer border border-cust-red w-32 ml-4 mr-4 shadow-md hover:shadow-cust-red text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
              >
                SEND
              </motion.button>)}
            </div>) }
            </>
          ) }
          <Link to="../" className="text-cust-red underline p-3 m-6 ">
            Go back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
