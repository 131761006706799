import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import { app } from "../firebase";


import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Loader from "./minis/Loader";

const auth = getAuth();
const db = getFirestore(app);
const SignUp = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [orgName, setOrgName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [signedUp, setSignedUp] = useState(false);
    const [alert, setAlert] = useState('');
    const [isLoading, setIsLoading] = useState(false);



  const signUpWithDetails = () => {
    setIsLoading(true);
    if( name == '' || email == '' || orgName == '' || password == '' ){
      setAlert('All the fields are required!');
    } else if( password.length < 8 ){
      setAlert('Password should have at least 8 characters!');
    } else {
    createUserWithEmailAndPassword(auth, email, password)
    .then(() => {
      try {
          const userRef = collection(db, 'users');
          setDoc(doc(userRef, `${email}`), {
            name: name,
            email: email,
            isAdmin: false,
            orgName: orgName,
            phone: phone,
            access: false,
          });
          setAlert('Application submitted for review!');
          setSignedUp(true);
        } catch (e) {
          setAlert(e);
        }
      // ...
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log(JSON.stringify(error));
      setAlert(errorMessage);
      // ..
    });
    }
    setIsLoading(false)
  };
  return (
    <div>
      <div className="flex h-screen justify-center items-center">
        <form onSubmit={e => { e.preventDefault() }} className="card flex-col p-4 mt-16 mb-16 items-center justify-center bg-cust-white rounded-lg w-fit ">
          <p className="text-cust-red p-3 m-6 ">Create a new account</p>
          <p className=' max-w-sm text-sm'> Please provide correct details. If the admin refuses your request, you won't be able to create another account with the same details.</p>
          <p className="text-cust-red p-3 m-3 ">{alert}</p>
          <div className=" border border-cust-red overflow-clip w-sm m-2 rounded-full text-center bg-cust-red justify-between items-center flex" >
            <span className=" w-20 right-0  h-full text-white ">Name</span>
            <input
              className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none "
              type="text"
              name="name"
              id="name"
              required
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
            />
          </div>
          <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex" >
            <span className=" w-20 right-0  h-full text-white ">Org</span>
            <input
              type="text"
              placeholder="Name of the Organisation"
              className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none"
              name="orgName"
              id="orgName"
              required
              onChange={(e) => setOrgName(e.target.value)}
            />
          </div>
          <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex" >
            <span className=" w-20 right-0  h-full text-white ">Email</span>
            <input
              className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none"
              type="email"
              name="email"
              required
              id="email"
              placeholder="E.g: email@mail.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex" >
            <span className=" w-20 right-0  h-full text-white ">Phone</span>
            <input
              className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none"
              type="number"
              name="ph-number"
              id="ph-number"
              required
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone Number"
            />
          </div>
          <div className=" border border-cust-red overflow-clip w-sm rounded-full m-2 text-center bg-cust-red justify-between items-center flex" >
            <span className=" w-20 right-0  h-full text-white ">Password</span>
            <input
              className=" h-full text-center bg-cust-white w-3/4 focus:ring-0 outline-none border-none"
              type="password"
              name="password"
              id="password"
              placeholder="Min. 8 characters"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex justify-around m-3 ">
            {isLoading ? (
              <Loader />
            ) : (
            <motion.button
              onClick={signUpWithDetails}
              whileTap={{ translateX: 4 }}
              className=" shadow-md hover:shadow-cust-red  cursor-pointer border border-cust-red w-32 ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
            >
              SIGN UP
            </motion.button>
            )}
          </div>
          <Link to="../" className="text-cust-red underline p-3 m-6 ">
            Back to Login
          </Link>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
