
import React, { useState, useRef, Fragment } from 'react'

import ReactPDF from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint  from 'react-to-print'
import RollerManual from './RollerManual';

const JobCard = ({ closeFn, order, products, adminData }) => {
  let jobCardPrintRef = useRef();

  const groupedProds = groupProducts(products, 'category', 'operation');

  return (
    <div className="container overflow-x-scroll">
      <div id='jobCard' ref={(el) => (jobCardPrintRef = el)}>
        <RollerManual order={order} products={groupedProds[0]} adminData={adminData} />
    </div>
    <div className=' flex gap-2'>
      <ReactToPrint
          trigger={() => <button
            className="flex right-0 shadow-md hover:shadow-green-600 items-center justify-center p-2 rounded-md  hover:bg-green-600 hover:text-white border text-green-600 border-green-600 "
          >
            Print
          </button>}
          content={() => jobCardPrintRef}
        />
        <button
        onClick={closeFn}
            className="flex right-0 shadow-md hover:shadow-red-600 items-center justify-center p-2 rounded-md  hover:bg-red-600 hover:text-white border text-red-600 border-red-600 "
          >
            Close
          </button>
          {/* <button
          onClick={() => generatePDF()}
            className="flex right-0 shadow-md hover:shadow-green-600 items-center justify-center p-2 rounded-md  hover:bg-green-600 hover:text-white border text-green-600 border-green-600 "
          >
            PDF
          </button> */}
    </div>
      </div>
  )
}
function groupByCategory(arr, categoryProp) {
  const grouped = [];
  for (const item of arr) {
    const category = item[categoryProp];
    let foundCategory = false;
    for (let i = 0; i < grouped.length; i++) {
      if (grouped[i][0][categoryProp] === category) {
        grouped[i].push(item);
        foundCategory = true;
        break;
      }
    }
    if (!foundCategory) {
      grouped.push([item]);
    }
  }
  return grouped;
}

function groupProducts(arr, categoryProp, operationProp) {
  const grouped = [];
  for (const item of arr) {
    const category = item[categoryProp];
    const operation = item[operationProp];
    let foundCategory = false;

    for (let i = 0; i < grouped.length; i++) {
      const existingCategory = grouped[i][0][categoryProp];
      const existingOperation = grouped[i][0][operationProp];

      if (existingCategory === category && existingOperation === operation) {
        grouped[i].push(item);
        foundCategory = true;
        break;
      }
    }

    if (!foundCategory) {
      grouped.push([item]);
    }
  }
  return grouped;
}


function generatePDF() {
  html2canvas(document.querySelector("#jobCard")).then((canvas) => {
    const imgData = canvas.toDataURL("image/png", 3.0);
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: [612, 792],
    });
    pdf.internal.scaleFactor = 1;
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    // Create a blob of the PDF data
    const blob = new Blob([pdf.output("blob")], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    // Open the PDF in a new tab for printing
    window.open(url, "_blank");
  });
}



export default JobCard;
