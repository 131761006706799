import React, { Fragment } from 'react'
import BottomTable from './minis/BottomTable';

const RollerManual = ({ order, products, adminData }) => {
    
  const table_headers = [
    "Sl.No",
    "Width",
    "Drop",
    "Shade",
    "Mechanism",
    "Operation",
    "Sqmtrs",
    "Location",
    "Roll No.",
    "Stand & Rack No.",
    "Remarks"
];
console.log(products);

  return (
    <div className=' flex flex-col gap-4 p-2'>
        <div className=' flex justify-between w-full'>
      <table style={{ textAlign: "center" }}>
        <tbody>
            <tr>
            <td 
                style={{
                  border: "1px solid black",
                  backgroundColor: "#757575",
                  color: "white",
                }}
                className=' text-sm'>
            Job Card No
          </td>
              <td style={{ border: "1px solid black", width: '200px' }}>
              <input
                type='text'
                defaultValue={products[0].category }
                style={{ border: "none", fontSize: "smaller", width: "100%" }}
              />
            </td>
            </tr>
            <tr>
            <td 
                style={{
                  border: "1px solid black",
                  backgroundColor: "#757575",
                  color: "white",
                }}
                className=' text-sm'>
            Date
          </td>
              <td style={{ border: "1px solid black" }}>
              <input
                type='text'
                defaultValue={( new Date()).toLocaleDateString()}
                style={{ border: "none", fontSize: "smaller", width: "100%" }}
              />
            </td>
            </tr>
            <tr>
            <td 
                style={{
                  border: "1px solid black",
                  backgroundColor: "#757575",
                  color: "white",
                }}
                className=' text-sm'>
            Project
          </td>
              <td style={{ border: "1px solid black" }}>
              <input
                type='text'
                defaultValue={order.projectName}
                style={{ border: "none", fontSize: "smaller", width: "100%" }}
              />
            </td>
            </tr>
        </tbody>
      </table>
      <div className=' font-belleza uppercase text-3xl h-full'>
        LOUVERLINE BLINDS PVT. LTD.
      </div>
        </div>
        <div className='text-center text-base'>Measurements of Roller Blinds</div>
      <table style={{ textAlign: "center" }}>
        <thead>
          <tr>
            {table_headers.map((colName) => (
              <th
                key={colName}
                style={{
                  border: "1px solid black",
                  backgroundColor: "#757575",
                  color: "white",
                  fontSize: "smaller"
                }}
              >
                {colName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {products.map((product, rowIndex) => (
            <Fragment key={product.orderID}>
              {Array(product.quantity).fill(null).map((_, index) => (
            <tr key={index}>
            <td style={{ border: "1px solid black", fontSize: "smaller", padding: '1px' }} >
            {rowIndex+1}
            </td>
              <td style={{ border: "1px solid black", fontSize: "smaller", padding: '1px' }}>
                <input
                  type='text'
                  defaultValue={toMeter(product.width, product.metrics)}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black", fontSize: "smaller", padding: '1px' }}>
                <input
                  type='text'
                  defaultValue={toMeter(product.length, product.metrics)}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black", fontSize: "smaller", width: '160px' }}>
              {product.fabricId}
              </td>
              <td style={{ border: "1px solid black", fontSize: "smaller", padding: '1px' }}>
              {product.operation}
              </td>
              <td style={{ border: "1px solid black", fontSize: "smaller", padding: '3px' }}>
              {product.operatingSide}
              </td>
              {/* <td style={{ border: "1px solid black" }}>
                <input
                  type='text'
                  defaultValue={product.fabricPrice}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td> */}
              <td style={{ border: "1px solid black" }}>
                <input
                  type='text'
                  defaultValue={rollerAreaFn(toMeter(product.width, product.metrics), toMeter(product.length, product.metrics), 1.115)}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black" }}>
                <input
                  type='text'
                  defaultValue={''}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black" }}>
                <input
                  type='text'
                  defaultValue={''}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black" }}>
                <input
                  type='text'
                  defaultValue={''}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black" }}>
                <input
                  type='text'
                  defaultValue={''}
                  style={{ border: "none", fontSize: "smaller", width: "100%" }}
                />
              </td>
            </tr>
            )
            )}
            </Fragment>
          )
          )}
        </tbody>
      </table>
      
      <BottomTable adminData={adminData} />
      </div>
  )
}


function toMeter(value, metrics) {
    if(metrics === 'CM'){
      return (value/100).toFixed(2);
    } else {
      return (value*2.54/100).toFixed(2);
    }
  }
  
  function rollerAreaFn(value1, value2, threshold) {
    const product = (value1 * value2).toFixed(2);;
    return product > threshold ? product : threshold;
  }

export default RollerManual
