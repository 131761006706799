import './App.css';
import { MainContainer, UserProfile, Contact, SignIn, SignUp, Users, PriceList, ForgotPassword, NewOrder, AdminOrders, Projects, Header, Footer, Loader, Project, NotFound } from './components'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { messaging, app } from './firebase';
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getToken } from 'firebase/messaging';
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

const db = getFirestore(app);

const App = () => {
  const loggedInUser = useSelector(state => (state.loggedInUser))
  
  const dispatch = useDispatch();
  
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  // const products = useSelector((state) => state.products);

  const fetchData = async () => {
    try {
      setProgress(80);
      const pricelist = await axios.get(process.env.REACT_APP_PRICELIST);
      dispatch({
        type: "SET_PRICELIST",
        pricelist: pricelist.data.data,
      });
      setProgress(90);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  async function fetchUser(){
  const userRef = doc(db, "users", JSON.parse(loggedInUser)?.email);
  const user = (await getDoc(userRef)).data();
  if(user.access === true){
  localStorage.setItem("loggedInUser", JSON.stringify(user));
  } else {
    logOut();
  }
  }
  
  const logOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch({
          type: "LOGOUT",
        });
        localStorage.clear();
      })
      .catch((error) => {
        // An error happened.
      });
  };

  async function requestNotificationPermission() {
    const permission = await Notification.requestPermission();
    if(permission === 'granted'){
      // Generate Token
      const token = await getToken(messaging, { vapidKey: "BGtZ92mpx_R-k23HMCQU9M5lvT0XFUdP3A8PpChYDHrLIt7rGen6wNwohHFBFRyancO2Z4RbLPCqx9U6nJLpug0" });
      console.log(token);
    } else if(permission === 'denied'){
      alert('You denied to receive the notifications!')
    }
  }

  useEffect(() => {
    if(loggedInUser){
      fetchUser();
    }
    fetchData();
  }, []);

  const projectsTog = () => {
    console.log('');
  }
  
  var isAdmin = false;
  if(loggedInUser){
    isAdmin = JSON.parse(loggedInUser)?.isAdmin;}
  
return (
  <div className="App h-screen text-sm w-screen">
    { !isLoading ? <div>
    <Header className='App-header' />
    <div className=''>
      <Routes>
        <Route path='/' element={ loggedInUser != null ? <MainContainer /> : <SignIn />}/>
        <Route path='/signUp' element={<SignUp />}/>
        <Route path='/contactus' element={<Contact />}/>
        <Route path='/users' element={ isAdmin ? <Users /> : ( loggedInUser ? <MainContainer />: <SignIn />)}/> 
        <Route path='/pricelist' element={ loggedInUser ? <PriceList /> : <SignIn />}/> 
        <Route path='/forgot' element={ <ForgotPassword />}/> 
        <Route path='/orders' element={ loggedInUser ? ( isAdmin ? <Projects userEmail={'admin'} toggleFn={projectsTog} /> : <Projects userEmail={JSON.parse(loggedInUser)?.email} />) : <SignIn />}/> 
        <Route exact path='/profile' element={ loggedInUser ? <UserProfile />: <SignIn />}/>
        <Route path='/order/:orderNumber' element={ loggedInUser ? <Project />: <SignIn />}/>
        <Route path='/user/:userEmail' element={ isAdmin ? <UserProfile />: ( loggedInUser ? <MainContainer />: <SignIn />)}/>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
    <Footer />
    </div> : <Loader type="main" progress={progress} /> }
  </div>
);
}

export default App
