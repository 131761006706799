import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { app } from "../firebase";

import {
  doc,
  getDoc,
  setDoc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { useState, useEffect } from "react";
import Projects from "./Projects";
import { TextareaAutosize } from "@mui/material";

const db = getFirestore(app);

const UserProfile = () => {
  const loggedInUser = useSelector(state => (state.loggedInUser))
  const [editProfile, setEditProfile] = useState(false);
  const [userPro, setUserPro] = useState(true);

  const { userEmail } = useParams();

  const [userInfo, setuserInfo] = useState();
  const storage = getStorage(app);

  const [userName, setUserName] = useState("");
  const [userMail, setUserMail] = useState("");
  const [orgName, setOrgName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddr, setUserAddr] = useState("");

  const getUserInfo = async () => {
    let usedEmail = "";
    if (userEmail) {
      usedEmail = userEmail;
    } else {
      usedEmail = JSON.parse(loggedInUser)?.email;
    }
    const userRef = doc(db, "users", usedEmail);
    const user = await getDoc(userRef);
    setuserInfo(user.data());
    setUserName(user.data().name);
    setUserMail(user.data().email);
    setOrgName(user.data().orgName);
    setUserPhone(user.data().phone);
    setUserAddr(user.data().address);
  };

  const resetEdit = () => {
    getUserInfo();
    toggleEdit();
  };

  const toggleEdit = () => {
    setEditProfile(!editProfile);
  };

  const toggleProfileView = () => {
    setUserPro(!userPro);
  }

  const submitEdit = () => {
    let usedEmail = "";
    if (userEmail) {
      usedEmail = userEmail;
    } else {
      usedEmail = JSON.parse(loggedInUser)?.email;
    }
    const userRef = doc(db, "users", usedEmail);
    setDoc(
      userRef,
      {
        name: userName,
        orgName: orgName,
        phone: userPhone,
        address: userAddr,
      },
      { merge: true }
    )
      .catch((error) => console.log(error))
      .then(resetEdit());
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="flex ml-3 mr-3  items-center min-h-screen mt-8">
      {userInfo && (
        <>
          <div className=" w-full flex flex-col md:flex-row p-4 mb-16 items-start justify-between">
            { userPro && <div className="w-full md:max-w-md p-6 card rounded-lg">
              <>
              <div className=" w-full ">
                <div className="text-center flex items-center justify-between">
                  {!editProfile ? (
                    <button
                      onClick={toggleEdit}
                      className="cursor-pointer items-center justify-center flex hover:shadow-cust-red text-center w-1/3 shadow-md bg-cust-red border border-cust-red text-cust-white pt-1 pb-1 rounded-md"
                    >
                      Edit Profile
                    </button>
                  ) : (
                    <button
                      onClick={resetEdit}
                      className="cursor-pointer  justify-center flex border items-center border-cust-red hover:shadow text-center w-1/3 bg-transparent text-cust-red pt-1 pb-1 rounded-md"
                    >
                      Cancel
                    </button>
                  )}
                  
                { userEmail === JSON.parse(loggedInUser)?.email && <Link
                to="../forgot"
                className="cursor-pointer text-center w-1/3 shadow-md bg-cust-red border border-cust-red text-cust-white md:pt-1 md:pb-1 rounded-md"
              >
                Reset Password
              </Link>}
              </div>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/louverline-signin.appspot.com/o/images%2Fprofiles%2Fuser.png?alt=media&token=8b4696b0-8a3f-4177-a77d-2f8665668dba"
                    alt="User Profile"
                    className="w-24 h-24 mx-auto mb-4 rounded-full border-4 border-white"
                  />
                  <input
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    disabled={!editProfile}
                    placeholder='Name'
                    className={ editProfile ? " border-b border-red-300 text-3xl text-center bg-transparent focus:outline-none  font-semibold font-belleza capitalize text-cust-red" : " text-3xl text-center bg-transparent focus:outline-none  font-semibold font-belleza capitalize text-cust-red"}
                  />
                  <input
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    disabled={!editProfile}
                    placeholder='Organisation'
                    className={ editProfile ? " border-b font-bold text-lg capitalize text-gray-600 bg-transparent focus:outline-none text-center" : " text-md capitalize text-gray-600 bg-transparent focus:outline-none text-center"}
                  />
                  {/* <p className="text-md capitalize text-gray-600">{userInfo.orgName}</p> */}
                </div>
                <div className="mt-8">
                  <div className="flex w-full justify-between items-center pb-4 border-b">
                    <span className="text-gray-500 w-fit">Email:</span>
                    <input
                      value={userMail}
                      onChange={(e) => setUserMail(e.target.value)}
                      disabled={true}
                      className=" text-gray-800 w-64 lowercase bg-transparent"
                    />
                  </div>
                  <div className="flex items-center justify-between border-b">
                    <span className="text-gray-500">Phone:</span>
                    <input
                      value={userPhone}
                      onChange={(e) => setUserPhone(e.target.value)}
                      disabled={!editProfile}
                      className={ editProfile ? " text-gray-900 py-4 bg-transparent text-center text-xl focus:outline-none rounded" : " text-gray-800 py-4 focus:outline-none bg-transparent"}
                    />
                  </div>
                  <div className="flex items-center justify-between py-4 border-b">
                    <span className="text-gray-500">Address:</span>
                    <input
                      value={userAddr}
                      onChange={(e) => setUserAddr(e.target.value)}
                      disabled={!editProfile}
                      className={ editProfile ? " text-gray-900 py-4 bg-transparent text-center text-xl focus:outline-none rounded" : " text-gray-800 py-4 focus:outline-none bg-transparent"}/>
                  </div>
                </div>
                {editProfile && (
                  <div className=" mt-2 flex justify-center">
                  <button
                    onClick={submitEdit}
                    className="cursor-pointer uppercase items-center justify-center flex hover:shadow-cust-red text-center w-1/3 shadow-md bg-cust-red border border-cust-red text-cust-white pt-1 pb-1 rounded-md"
                  >
                    submit
                  </button></div>
                )}
              </>
            </div>}
            <div className=" -mt-8 md:-mt-24 w-full">
              <Projects toggleFn={toggleProfileView} userEmail={userInfo.email} fromPage={'userProfile'} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserProfile;
