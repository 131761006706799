import {
  Body,
  Container,
  Column,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
} from '@react-email/components';
import * as React from 'react';

export const EmailTemp = ({ order, products, remarks, userDet, subtotal, heading, mainText, emailBodyText, deliveryMode, freightPrice, packagingPrice }) => (
  <Html>
    <Head />
    <Body style={main}>
      <Container style={container}>
        <Section style={track.container}>
          <Row>
            <Column align="left">
              <Link style={{ cursor: 'pointer' }} href='https://www.mylouverline.com'>
          <Img
            src={`https://mylouverline.com/static/media/logo1.4836b88a21b10241dc38.png`}
            width="80px"
            height="40px"
            alt="Louverline Blinds"
            style={{ margin: 'auto' }}
          />
              </Link>
            </Column>
            <Column align='right'>
              <Text style={track.number}>PO Number: {order.pONumber}</Text>
              <Text style={track.number}>Order ID: {order.projectID}</Text>
            </Column>
          </Row>
        </Section>
        <Hr style={global.hr} />
        <Section style={message}>
          <Heading style={{...global.heading, color: '#AF2925'}}> {heading} </Heading>
          <Text style={global.paragraphWithBold}>
            {remarks}
          </Text>
          <Text style={global.text}>
            {mainText}
          </Text>
          
            { order.docketNumber && 
          <Text style={global.paragraphWithBold}>
            Delivery Details: {order.docketNumber}
          </Text> }
          <Row style={{ width: '100%' }}>
            { order.trackingLink && <Column align="center">
              <Link href={`${order.trackingLink}`} style={{...global.button, borderRadius: '9999px', border: '0px' , marginTop: '20px', backgroundColor: '#AF2925', color: '#fff'}}>Track order</Link>
            </Column>}
          </Row>
          <Text style={{ ...global.text, marginTop: 24 }}>
            {emailBodyText}
          </Text>
        </Section>
        {/* <Hr style={global.hr} />
        <Section
          style={{ ...paddingX, paddingTop: '40px', paddingBottom: '40px' }}
        >
          <Row>
            <Column style={{ verticalAlign: 'top', paddingLeft: '12px' }}>
              <Text style={global.paragraphWithBold}>Order Number</Text>
            </Column>
          </Row>
        </Section> */}
        <Hr style={global.hr} />
        <Section style={{...global.defaultPadding, marginBottom: 20}}>
          <Row style={{ display: 'inline-flex', marginBottom: 20, justifyContent: 'space-between' }}>
            <Column style={{ width: '170px' }}>
              <Text style={global.paragraphWithBold}>Product</Text>
            </Column>
            <Column style={{ width: '170px' }}>
              <Text style={global.paragraphWithBold}>Dimension (L×W)</Text>
            </Column>
            <Column style={{ width: '120px' }}>
              <Text style={global.paragraphWithBold}>Price</Text>
            </Column>
            <Column style={{ width: '50px' }}>
              <Text style={global.paragraphWithBold}>Quantity</Text>
            </Column>
          </Row>
          { products.map(prod => (
            <Row key={prod.orderID} style={{ display: 'inline-flex', marginBottom: 20, justifyContent: 'space-between' }}>
            <Column style={{ width: '170px' }}>
              <Text style={global.text}>{prod.category}</Text>
              <Text style={global.text}>({prod.fabricId})</Text>
            </Column>
            <Column style={{ width: '170px' }}>
              <Text style={global.text}>{prod.length} {prod.metrics} × {prod.width} {prod.metrics}</Text>
            </Column>
            <Column style={{ width: '120px' }}>
              <Text style={global.text}>{"\u20B9"}{prod.totalPrice}</Text>
            </Column>
            <Column style={{ width: '80px' }}>
              <Text style={global.text}>{prod.quantity}</Text>
            </Column>
          </Row>
          )) }
          <Row style={{ width: '100%' }}>
            <Column style={{ width: '200px' }} align='left'>
            <Text style={{...global.paragraphWithBold, margin: 'auto'}}>Subtotal (Excl. GST)</Text>
            </Column>
            <Column style={{ width: '100px' }} align='right'>
            <Text style={global.paragraphWithBold}>{"\u20B9"}{subtotal?.toFixed(2)}</Text>
            </Column>
          </Row>
          {/* { freightPrice && <Row style={{ width: '100%' }}>
            <Column style={{ width: '200px' }} align='left'>
            <Text style={{...global.text, margin: 'auto'}}>Freight Charges: </Text>
            </Column>
            <Column style={{ width: '100px' }} align='right'>
            <Text style={global.text}>{"\u20B9"}{freightPrice?.toFixed(2)}</Text>
            </Column>
          </Row>} */}
          { order.freightPrice && <Row style={{ width: '100%' }}>
            <Column style={{ width: '200px' }} align='left'>
            <Text style={{...global.text, margin: 'auto'}}>Freight Charges:({order.deliveryMode}) </Text>
            </Column>
            <Column style={{ width: '100px' }} align='right'>
            <Text style={global.text}>{"\u20B9"}{order.freightPrice}</Text>
            </Column>
          </Row>}
          {/* { packagingPrice && <Row style={{ width: '100%' }}>
            <Column style={{ width: '200px' }} align='left'>
            <Text style={{...global.text, margin: 'auto'}}>Packaging Charges: </Text>
            </Column>
            <Column style={{ width: '100px' }} align='right'>
            <Text style={global.text}>{"\u20B9"}{packagingPrice?.toFixed(2)}</Text>
            </Column>
          </Row>} */}
          { order.packagingPrice && <Row style={{ width: '100%' }}>
            <Column style={{ width: '200px' }} align='left'>
            <Text style={{...global.text, margin: 'auto'}}>Packaging Charges: </Text>
            </Column>
            <Column style={{ width: '100px' }} align='right'>
            <Text style={global.text}>{"\u20B9"}{order.packagingPrice}</Text>
            </Column>
          </Row>}
          <Hr />
          { order.freightPrice && order.packagingPrice && <Row style={{ width: '100%' }}>
            <Column style={{ width: '200px' }} align='left'>
            <Text style={{...global.paragraphWithBold, margin: 'auto'}}>Total (Excl. GST)</Text>
            </Column>
            <Column style={{ width: '100px' }} align='right'>
            <Text style={global.paragraphWithBold}>{"\u20B9"}{(subtotal +( order.freightPrice ? Number(order.freightPrice) : 0) + ( order.packagingPrice ? Number(order.packagingPrice) : 0)).toFixed(2)}</Text>
            </Column>
          </Row>}
          <Row style={{ width: '100%' }}>
            <Column align="center">
              <Link href={`http://mylouverline.com/order/${order.projectID}`} style={{...global.button, borderRadius: '9999px', marginTop: '20px', color: '#000'}}>Go to Order</Link>
            </Column>
          </Row>
        </Section>
        <Hr style={global.hr} />
        <Section style={message}>
          <Text style={adressTitle}>Ordered by: {userDet.name}</Text>
          <Text style={{ ...global.text, fontSize: 14 }}>
            {userDet.orgName} | {userDet.email} | {userDet.phone}
          </Text>
        </Section>
        {/* <Hr style={global.hr} />
        <Section style={paddingY}>
          <Text style={global.heading}>Top Picks For You</Text>

          <Row style={recomendations.container}>
            <Column
              style={{ ...recomendations.product, paddingLeft: '4px' }}
              align="center"
            >
              <Img
                src={`${baseUrl}/static/nike-recomendation-1.png`}
                alt="Brazil 2022/23 Stadium Away Women's Nike Dri-FIT Soccer Jersey"
                width="100%"
              />
              <Text style={recomendations.title}>
                USWNT 2022/23 Stadium Home
              </Text>
              <Text style={recomendations.text}>
                Women's Nike Dri-FIT Soccer Jersey
              </Text>
            </Column>
            <Column style={recomendations.product} align="center">
              <Img
                src={`${baseUrl}/static/nike-recomendation-2.png`}
                alt="Brazil 2022/23 Stadium Away Women's Nike Dri-FIT Soccer Jersey"
                width="100%"
              />
              <Text style={recomendations.title}>
                Brazil 2022/23 Stadium Goalkeeper
              </Text>
              <Text style={recomendations.text}>
                Men's Nike Dri-FIT Short-Sleeve Football Shirt
              </Text>
            </Column>
            <Column style={recomendations.product} align="center">
              <Img
                src={`${baseUrl}/static/nike-recomendation-4.png`}
                alt="Brazil 2022/23 Stadium Away Women's Nike Dri-FIT Soccer Jersey"
                width="100%"
              />
              <Text style={recomendations.title}>FFF</Text>
              <Text style={recomendations.text}>Women's Soccer Jacket</Text>
            </Column>
            <Column
              style={{ ...recomendations.product, paddingRight: '4px' }}
              align="center"
            >
              <Img
                src={`${baseUrl}/static/nike-recomendation-4.png`}
                alt="Brazil 2022/23 Stadium Away Women's Nike Dri-FIT Soccer Jersey"
                width="100%"
              />
              <Text style={recomendations.title}>FFF</Text>
              <Text style={recomendations.text}>
                Women's Nike Pre-Match Football Top
              </Text>
            </Column>
          </Row>
        </Section> */}
        {/* <Hr style={global.hr} />
        <Section style={menu.container}>
          <Text style={menu.title}>Get Help</Text>
          <Row style={menu.content}>
            <Column style={{ width: '33%' }} colSpan={1}>
              <Link href="/" style={menu.text}>
                Shipping Status
              </Link>
            </Column>
            <Column style={{ width: '33%' }} colSpan={1}>
              <Link href="/" style={menu.text}>
                Shipping & Delivery
              </Link>
            </Column>
            <Column style={{ width: '33%' }} colSpan={1}>
              <Link href="/" style={menu.text}>
                Returns & Exchanges
              </Link>
            </Column>
          </Row>
          <Row style={{ ...menu.content, paddingTop: '0' }}>
            <Column style={{ width: '33%' }} colSpan={1}>
              <Link href="/" style={menu.text}>
                How to Return
              </Link>
            </Column>
            <Column style={{ width: '66%' }} colSpan={2}>
              <Link href="/" style={menu.text}>
                Contact Options
              </Link>
            </Column>
          </Row>
          <Hr style={global.hr} />
          <Row style={menu.tel}>
            <Column>
              <Row>
                <Column style={{ width: '16px' }}>
                  <Img
                    src={`${baseUrl}/static/nike-phone.png`}
                    width="16px"
                    height="26px"
                    style={{ paddingRight: '14px' }}
                  />
                </Column>
                <Column>
                  <Text style={{ ...menu.text, marginBottom: '0' }}>
                    1-800-806-6453
                  </Text>
                </Column>
              </Row>
            </Column>
            <Column>
              <Text
                style={{
                  ...menu.text,
                  marginBottom: '0',
                }}
              >
                4 am - 11 pm PT
              </Text>
            </Column>
          </Row>
        </Section> */}
        {/* <Hr style={global.hr} />
        <Section style={paddingY}>
          <Text style={global.heading}>Nike.com</Text>
          <Row style={categories.container}>
            <Column align="center">
              <Link href="/" style={categories.text}>
                Men
              </Link>
            </Column>
            <Column align="center">
              <Link href="/" style={categories.text}>
                Women
              </Link>
            </Column>
            <Column align="center">
              <Link href="/" style={categories.text}>
                Kids
              </Link>
            </Column>
            <Column align="center">
              <Link href="/" style={categories.text}>
                Customize
              </Link>
            </Column>
          </Row>
        </Section> */}
        <Hr style={{ ...global.hr, marginTop: '12px' }} />
        <Section style={{...paddingY, backgroundColor: '#3E3E3E'}}>
          <Text style={{ ...footer.text, paddingTop: 30 }}>
          Note: This is a system-generated email. Please do not reply to this email.
          </Text>
          <Text style={{ ...footer.text, paddingBottom: 30, color: 'white' }}>
          Contact orders@louverlineblinds.com if you have any questions.
          </Text>
          <Link style={{ cursor: 'pointer' }} href='https://www.mylouverline.com'>
          <Img
            src={`https://mylouverline.com/static/media/logo1.4836b88a21b10241dc38.png`}
            width="80px"
            height="40px"
            alt="Louverline Blinds"
            style={{ margin: 'auto' }}
          />
          </Link>
          <Text style={footer.text}>
          © {new Date().getFullYear()} Louverline Blinds Pvt. Ltd. | All Rights Reserved
          </Text>
          <Text style={{...footer.text, paddingBottom: 30}}>
          No 43/2, Singasnadra, Electronic city post, Bangalore, Karnataka, India 560100
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

export default EmailTemp;

const paddingX = {
  paddingLeft: '40px',
  paddingRight: '40px',
};

const paddingY = {
  paddingTop: '22px',
  paddingBottom: '22px',
};

const paragraph = {
  margin: '0',
  lineHeight: '2',
};

const global = {
  paddingX,
  paddingY,
  defaultPadding: {
    ...paddingX,
    ...paddingY,
  },
  paragraphWithBold: { ...paragraph, fontWeight: 'bold' },
  heading: {
    fontSize: '32px',
    lineHeight: '1.3',
    fontWeight: '700',
    textAlign: 'center',
    letterSpacing: '-1px',
    fontFamily:
      '"Belleza", sans-serif',
  },
  text: {
    ...paragraph,
    color: '#747474',
    fontWeight: '500',
  },
  button: {
    border: '1px solid #929292',
    fontSize: '16px',
    textDecoration: 'none',
    padding: '10px 0px',
    width: '220px',
    display: 'block',
    textAlign: 'center',
    fontWeight: 500,
  },
  hr: {
    borderColor: '#E5E5E5',
    margin: '0',
  },
};

const main = {
  backgroundColor: '#ffffff',
  fontFamily:
    '"Quicksand", sans-serif',
};

const container = {
  margin: '10px auto',
  width: '600px',
  border: '1px solid #E5E5E5',
  borderRadius: '10px',
};

const track = {
  container: {
    width: '100%',
    backgroundColor: '#3E3E3E',
  },
  number: {
    margin: '12px 10px 0 0',
    fontWeight: 500,
    lineHeight: '1.4',
    color: '#ffffff',
  },
};

const message = {
  padding: '40px 74px',
  textAlign: 'center',
};

const adressTitle = {
  ...paragraph,
  fontSize: '15px',
  fontWeight: 'bold',
};

const recomendationsText = {
  margin: '0',
  fontSize: '15px',
  lineHeight: '1',
  paddingLeft: '10px',
  paddingRight: '10px',
};

const recomendations = {
  container: {
    padding: '20px 0',
  },
  product: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  title: { ...recomendationsText, paddingTop: '12px', fontWeight: '500' },
  text: {
    ...recomendationsText,
    paddingTop: '4px',
    color: '#747474',
  },
};

const menu = {
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    backgroundColor: '#F7F7F7',
  },
  content: {
    ...paddingY,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  title: {
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '13.5px',
    marginTop: 0,
    fontWeight: 500,
    color: '#000',
  },
  tel: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '32px',
    paddingBottom: '22px',
  },
};

const categories = {
  container: {
    width: '370px',
    margin: 'auto',
    paddingTop: '12px',
  },
  text: {
    fontWeight: '500',
    color: '#000',
  },
};

const footer = {
  policy: {
    width: '166px',
    margin: 'auto',
  },
  text: {
    margin: '0',
    color: '#E8E8E6',
    fontSize: '13px',
    textAlign: 'center',
  },
};
