import React, { useEffect, useState } from "react";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import { app } from "../firebase";
import Loader from "./minis/Loader";
import { motion } from "framer-motion";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import rollerBlind from "./utils/CategoryData/rollerBlind.json";
import aluminium from "./utils/CategoryData/aluminium.json";
import wooden from "./utils/CategoryData/wooden.json";
import outlook from "./utils/CategoryData/outlook.json";
import symphonyData from "./utils/CategoryData/symphonyData.json";

import RadioOption from "./RadioOption";
const db = getFirestore(app);
const categories = [
  "roller",
  "delite",
  "symphony",
  "outlook",
  "wood",
  "newaluminium",
];

const NewOrder = ({ refreshPage, projectID }) => {
  
  const loggedInUser = useSelector(state => (state.loggedInUser))


  const dispatch = useDispatch();
  const [cat, setCat] = useState([]);
  const [system, setSystem] = useState([]);
  const [medium, setMedium] = useState([]);
  const [operation, setOperation] = useState([]);
  const [motorType, setMotorType] = useState([]);
  const [motor, setMotor] = useState([]);
  const [fabricId, setFabricId] = useState("");
  const roller = useSelector((state) => state.rollerFabricData);
  const outlookData = useSelector((state) => state.outlookData);
  const newaluminium = useSelector((state) => state.newaluminiumData);
  const symphony = useSelector((state) => state.symphonyData);
  const delite = useSelector((state) => state.deliteData);
  const wood = useSelector((state) => state.woodData);
  const pricelist = useSelector((state) => state.pricelist);
  const [ids, setIds] = useState([]);
  const [mids, setMids] = useState([]);
  const [products, setProducts] = useState([]);
  const [fabricPrice, setFabricPrice] = useState(0);
  const [fabricStyle, setFabricStyle] = useState("");
  const [alert, setAlert] = useState("");
  const [priceData, setPriceData] = useState({});
  const [selectedFabric, setSelectedFabric] = useState({});

  const [res, setRes] = useState(false);
  const [actArea, setActArea] = useState(1);

  // Final Data to be uploaded
  const [category, setCategory] = useState("");
  const [systemFinal, setSystemFinal] = useState("");
  const [mediumFinal, setMediumFinal] = useState("");
  const [operate, setOperate] = useState("");
  const [operatingSide, setOperatingSide] = useState("");
  const [operationPrice, setOperationPrice] = useState(0);
  const [motorTypeFinal, setMotorTypeFinal] = useState("");
  const [motorFinal, setMotorFinal] = useState("");
  const [motorPrice, setMotorPrice] = useState(0);
  const [switchFinal, setSwitchFinal] = useState("");
  const [fabric, setFabric] = useState("");
  const [remote, setRemote] = useState("");
  const [remotePrice, setRemotePrice] = useState(0);
  const [metrics, setMetrics] = useState("");
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [ladderFinal, setLadderFinal] = useState("");
  const [ballFinal, setBallFinal] = useState("");
  const [addOn, setAddOn] = useState("");
  const [addOnPrice, setAddOnPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [area, setArea] = useState(1);

  
  // Fetch IDs

  const fetchSheetData = async (cat) => {
    clearData();
    setIsLoading(true);
    switch (cat) {
      case "roller":
        if (roller.length < 1) {
          try {
            const rollerFabricData = await axios.get(
              process.env.REACT_APP_ROLLER_FABRIC_DATA
            );
            dispatch({
              type: "SET_ROLLER",
              rollerData: rollerFabricData.data.data,
            });
            setProducts(rollerFabricData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(roller);
        }
        setCat([...symphonyData]);
        setCategory("Roller");
        setPriceData(pricelist.rollersPrice);
        setIsLoading(false);
        break;
      case "outlook":
        if (outlookData.length < 1) {
          try {
            const outlookData = await axios.get(
              process.env.REACT_APP_OUTLOOK_DATA
            );
            dispatch({
              type: "SET_OUTLOOK",
              outlookData: outlookData.data.data,
            });
            setProducts(outlookData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(outlookData);
        }
        setIsLoading(false);
        setCat([...outlook]);
        setCategory("Outlook");
        setPriceData(pricelist.outlookPrice);
        break;
      case "delite":
        if (delite.length < 1) {
          try {
            const deliteData = await axios.get(
              process.env.REACT_APP_DELITE_DATA
            );
            dispatch({
              type: "SET_DELITE",
              deliteData: deliteData.data.data,
            });
            setProducts(deliteData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(delite);
        }
        setIsLoading(false);
        setCat([...symphonyData]);
        setCategory("Delite");
        setPriceData(pricelist.delitePrice);
        break;
      case "symphony":
        if (symphony.length < 1) {
          try {
            const symphonyData = await axios.get(
              process.env.REACT_APP_SYMPHONY_DATA
            );
            dispatch({
              type: "SET_SYMPHONY",
              symphonyData: symphonyData.data.data,
            });
            setProducts(symphonyData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(symphony);
        }
        setIsLoading(false);
        setCat([...symphonyData]);
        setCategory("Symphony");
        setPriceData(pricelist.symphonyPrice);
        break;
      case "wood":
        if (wood.length < 1) {
          try {
            const woodData = await axios.get(process.env.REACT_APP_WOOD_DATA);
            dispatch({
              type: "SET_WOOD",
              woodData: woodData.data.data,
            });
            setProducts(woodData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(wood);
        }
        setIsLoading(false);
        setCat([...wooden]);
        setCategory("New Wood & Faux Wood");
        setPriceData(pricelist.woodPrice);
        break;
      case "newaluminium":
        if (newaluminium.length < 1) {
          try {
            const newaluminiumData = await axios.get(
              process.env.REACT_APP_NEW_ALUMINIUM_DATA
            );
            dispatch({
              type: "SET_NEWALUMINIUM",
              newaluminiumData: newaluminiumData.data.data,
            });
            setProducts(newaluminiumData.data.data);
          } catch (error) {
            console.log(error);
          }
        } else {
          setProducts(newaluminium);
        }
        setIsLoading(false);
        setCat([...aluminium]);
        setCategory("New Aluminium");
        setPriceData(pricelist.aluminiumPrice);
        break;

      default:
        break;
    }
  };

  // const fetchIds = (cat) => {
  //   switch (cat) {
  //     case "roller":
  //       setProducts(roller);
  //       setCat([...symphonyData]);
  //       setCategory("Roller");
  //       setPriceData(pricelist.rollersPrice);
  //       break;

  //     case "delite":
  //       setProducts(delite);
  //       setCat([...symphonyData]);
  //       setCategory("Delite");
  //       setPriceData(pricelist.delitePrice);
  //       break;

  //     case "newaluminium":
  //       setProducts(newaluminium);
  //       setCat([...aluminium]);
  //       setCategory("New Aluminium");
  //       setPriceData(pricelist.aluminiumPrice);
  //       break;

  //     case "outlook":
  //       setProducts(outlookData);
  //       setCat([...outlook]);
  //       setCategory("Outlook");
  //       setPriceData(pricelist.outlookPrice);
  //       break;
  //     case "symphony":
  //       setProducts(symphony);
  //       setCat([...symphonyData]);
  //       setCategory("Symphony");
  //       setPriceData(pricelist.symphonyPrice);
  //       break;

  //     case "wood":
  //       setProducts(wood);
  //       setCat([...wooden]);
  //       setCategory("New Wood & Faux Wood");
  //       setPriceData(pricelist.woodPrice);
  //       break;

  //     default:
  //       break;
  //   }
  //   clearData();
  // };

  const searchFn = (ids, input) => {
    const regex = new RegExp(input.split("").join(".*"), "i");
    setMids(ids.filter((id) => regex.test(id)));
  };

  const inputFn = (value) => {
    setFabricId(value);
    setFabric(value);
    for (let index = 0; index < priceData.length; index++) {
      if (
        priceData[index].prod_id
          .split(" ")
          .join("")
          .toLowerCase() == value.toLowerCase()
      ) {
        setFabricStyle(priceData[index].prod_style);
        setSelectedFabric(priceData[index]);
        if (category == "New Wood & Faux Wood") {
          setFabricPrice(priceData[index].str_price_ft);
        } else if (category == "Outlook") {
          setFabricPrice(priceData[index].std_hook_price_ft);
        } else {
          setFabricPrice(priceData[index].price_ft);
        }
      }
    }
  };

  // search the IDs and show the dropdown

  useEffect(() => {
    if (priceData?.length > 0) {
      const uniqueIds = new Set(priceData.map((item) => item.prod_id));
      setIds(Array.from(uniqueIds));
      setIsLoading(false);
    }
    searchFn(ids, fabricId);
  }, [fabricId]);

  // Calculates the area on changing length & width

  useEffect(() => {
    if (metrics == "CM") {
      setActArea((length * width * 0.00107639).toFixed(2));
      if (category == "Outlook") {
        if (length * width * 0.00107639 > 27) {
          setArea((length * width * 0.00107639).toFixed(2));
        } else {
          setArea(27);
        }
      } else {
        if (length * width * 0.00107639 > 12) {
          setArea((length * width * 0.00107639).toFixed(2));
        } else {
          setArea(12);
        }
      }
    } else {
      setActArea((length * width * 0.00694444).toFixed(2));
      if (category == "Outlook") {
        if (length * width * 0.00694444 > 27) {
          setArea((length * width * 0.00694444).toFixed(2));
        } else {
          setArea(27);
        }
      } else {
        if (length * width * 0.00694444 > 12) {
          setArea((length * width * 0.00694444).toFixed(2));
        } else {
          setArea(12);
        }
      }
    }
  }, [length, width]);

  // Checks the stock if available or not

  useEffect(() => {
    var x = 0;
    var idhai = false;
    for (let i = 0; i < products.length; i++) {
      if (
        products[i].prod_id
          .split(" ")
          .join("")
          .toLowerCase() == fabric.toLowerCase()
      ) {
        x += Number(products[i].quantity);
        idhai = true;
      }
    }
    if (actArea > 0 && actArea < 0.8 * x) {
      setRes(true);
    } else {
      setRes(false);
    }
  }, [length, width, fabric, actArea]);

  const handleSys = (id) => {
    for (let i = 0; i < cat.length; i++) {
      if (cat[i].id == id) {
        setSystem([...cat[i].data]);
        setSystemFinal(cat[i].name);
        if (cat[i].name == "Outlook XL System") {
          setFabricPrice(selectedFabric.xl_cable_price_ft);
        } else {
          setFabricPrice(selectedFabric.std_hook_price_ft);
        }
        setMedium([]);
        setMediumFinal("");
        setOperate("");
        setOperation([]);
        setMotorType([]);
        setMotorTypeFinal("");
        setMotor([]);
        setMotorFinal("");
        setMotorPrice(0);
        setRemote("");
        setRemotePrice(0);
        setBallFinal("");
        setLadderFinal("");
        setBallFinal("");
        setLadderFinal("");
      }
    }
  };
  const handleMedium = (id) => {
    for (let i = 0; i < system.length; i++) {
      if (system[i].id == id) {
        setMedium([...system[i].data]);
        setMediumFinal(system[i].name);
        if (systemFinal == "Outlook XL System") {
          if (system[i].name == "With Bottom Hook") {
            setFabricPrice(selectedFabric.xl_hook_price_ft);
          } else {
            setFabricPrice(selectedFabric.xl_cable_price_ft);
          }
        } else {
          setFabricPrice(selectedFabric.std_hook_price_ft);
        }
        setOperate("");
        setOperation([]);
        setMotorType([]);
        setMotorTypeFinal("");
        setMotor([]);
        setMotorFinal("");
        setMotorPrice(0);
        setRemote("");
        setRemotePrice(0);
        setBallFinal("");
        setLadderFinal("");
        setBallFinal("");
        setLadderFinal("");
      }
    }
  };
  const handleOutlookOp = (id) => {
    for (let i = 0; i < medium.length; i++) {
      if (medium[i].id == id) {
        setOperate(medium[i].name);
        setOperation([...medium[i].data]);
        setOperationPrice(medium[i].price);
        setMotorType([]);
        setMotorTypeFinal("");
        setMotor([]);
        setMotorFinal("");
        setMotorPrice(0);
        setRemote("");
        setRemotePrice(0);
        setBallFinal("");
        setLadderFinal("");
        setBallFinal("");
        setLadderFinal("");
      }
    }
    if (id == 1) {
      setAddOn("Crank");
      setAddOnPrice(875);
    } else {
      setAddOn("");
      setAddOnPrice(0);
    }
  };
  const handleOp = (id) => {
    for (let i = 0; i < cat.length; i++) {
      if (cat[i].id == id) {
        setOperate(cat[i].name);
        setOperationPrice(cat[i].price);
        setMotorType([]);
        setMotorTypeFinal("");
        setMotor([]);
        setMotorFinal("");
        setMotorPrice(0);
        setRemote("");
        setRemotePrice(0);
        setBallFinal("");
        setLadderFinal("");
        setBallFinal("");
        setLadderFinal("");
        if (category == "New Wood & Faux Wood") {
          if (cat[i].name == "Motorised") {
            setFabricPrice(selectedFabric.motor_price_ft);
          } else {
            setFabricPrice(selectedFabric.str_price_ft);
          }
        }
        setOperation([...cat[i].data]);
      }
    }
  };
  const handleMotorType = (id) => {
    for (let i = 0; i < operation.length; i++) {
      if (operation[i].id == id) {
        setMotorTypeFinal(operation[i].name);
        if (category == "New Wood & Faux Wood") {
          if (operate != "Motorised") {
            if (operation[i].name == "Monochain") {
              setFabricPrice(selectedFabric.mono_price_ft);
            } else {
              if (ladderFinal == "Tape Ladder") {
                setFabricPrice(selectedFabric.tape_price_ft);
              } else {
                setFabricPrice(selectedFabric.str_price_ft);
              }
            }
          }
        }
        if (operation[i].data) {
          setMotorType([...operation[i].data]);
        }
        setMotor([]);
        setMotorFinal("");
        setMotorPrice(0);
        setRemote("");
        setRemotePrice(0);
        setBallFinal("");
        setLadderFinal("");
      }
    }
  };
  const handleMotor = (id) => {
    for (let i = 0; i < motorType.length; i++) {
      if (motorType[i].id == id) {
        setMotorFinal(motorType[i].name);
        setMotorPrice(motorType[i].price);
        setBallFinal("");
        setLadderFinal("");
        if (motorType[i].data) {
          setMotor([...motorType[i].data]);
        }
      }
    }
  };
  const handleRemote = (id) => {
    for (let i = 0; i < motor.length; i++) {
      if (motor[i].id == id) {
        setRemote(motor[i].name);
        setRemotePrice(motor[i].price);
      }
    }
  };
  const handleLadder = (ladder) => {
    setLadderFinal(ladder);
    if (motorTypeFinal == "Cord / Lock") {
      if (ladder == "Tape Ladder") {
        setFabricPrice(selectedFabric.tape_price_ft);
      } else {
        setFabricPrice(selectedFabric.str_price_ft);
      }
    } else {
      setFabricPrice(selectedFabric.mono_price_ft);
    }
  };

  const handleAddOn = (ele) => {
    setAddOn(ele);
    switch (ele) {
      case "Crank":
        setAddOnPrice(875);
        break;
      case "Pelmet/Fascia":
        setAddOnPrice(30);
        break;

      default:
        setAddOnPrice(0);
        break;
    }
  };

  // Submit the order

  const submitOrder = () => {
    try {
      const orderRef = collection(db, "orders", `${projectID}`, "products");
      var totalPrice;
      if (category == "Outlook") {
        totalPrice = Number(
          (fabricPrice + operationPrice) * area +
            motorPrice +
            remotePrice +
            addOnPrice
        ).toFixed(2);
      } else {
        totalPrice = Number(
          (fabricPrice + operationPrice + addOnPrice) * area +
            motorPrice +
            remotePrice
        ).toFixed(2);
      }
      var currTime = Date.now();
      setDoc(doc(orderRef, `${currTime}`), {
        orderID: `${currTime}`,
        projectID: projectID,
        category: category,
        fabricId: fabric,
        metrics: metrics,
        length: length,
        width: width,
        system: systemFinal,
        medium: mediumFinal,
        operation: operate,
        operatingSide: operatingSide,
        motorType: motorTypeFinal,
        motor: motorFinal,
        switch: switchFinal,
        remote: remote,
        ball: ballFinal,
        ladder: ladderFinal,
        adminremarks: "",
        addOn: addOn,
        addOnPrice: addOnPrice,
        status: "pending",
        quantity: 1,
        fabricPrice: fabricPrice,
        operationPrice: operationPrice,
        motorPrice: motorPrice,
        remotePrice: remotePrice,
        area: area,
        totalPrice: totalPrice,
      });
      setAlert(
        "Order Added to the project, please click on Go Back or select a category to add a new product!"
      );
      clearData();
      setCat([]);
      setCategory("");
      refreshPage();
    } catch (e) {
      setAlert(e.message);
    }
  };

  // Clears the Form

  const clearData = () => {
    setSystemFinal("");
    setMediumFinal("");
    setAddOn("");
    setAddOnPrice(0);
    setSystem([]);
    setMedium([]);
    setOperate("");
    setMotorFinal("");
    setMotorTypeFinal("");
    setFabric("");
    setSelectedFabric({});
    setLength(0);
    setWidth(0);
    setOperation([]);
    setOperatingSide("");
    setOperationPrice(0);
    setMotorType([]);
    setMotor([]);
    setMotorPrice(0);
    setFabricId("");
    setMetrics("");
    setRemote("");
    setRemotePrice(0);
    setBallFinal("");
    setLadderFinal("");
  };
  return (
    <div className="flex h-full rounded-lg bg-cust-white w-full justify-center">
      <div>
        <div className=" flex flex-col justify-center items-center w-full">
          <div className=" w-full m-2 p-1 ">
            <p className=" text-center text-cust-red"> Select a category </p>
            <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
              {categories.map((ele) => (
                <label key={ele} className="cursor-pointer">
                  <input
                    type="radio"
                    className="peer sr-only"
                    name="category"
                    value={ele}
                    onChange={(e) => fetchSheetData(e.target.value)}
                  />
                  <div className="w-40 max-w-xl rounded-md bg-white peer-checked:bg-red-50 ring-1 ring-offset-1 ring-transparent peer-checked:ring-cust-red p-4 text-gray-500 transition-all hover:shadow peer-checked:text-cust-red ">
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-semibold uppercase">{ele}</p>
                        <div>
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </div>
          {isLoading ? <Loader /> : <>
          <div className=" flex flex-col">
            {cat.length > 0 && (
              <div className=" border border-cust-red hover:shadow overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                <span className=" w-full text-center left-0 h-full text-white">
                  Fabric Code
                </span>
                <input
                  type="text"
                  className=" h-full text-center bg-white w-44 focus:ring-0 outline-none border-none "
                  name="prod_id"
                  id="prod_id"
                  placeholder=""
                  value={fabricId}
                  onChange={(e) =>
                    setFabricId(
                      e.target.value
                        .split(" ")
                        .join("")
                        .toLowerCase()
                    )
                  }
                />
              </div>
            )}
            <ul className={`relative max-h-0 z-10 bg-white `}>
              {mids?.map(
                (id) =>
                  fabricId != "" &&
                  fabricId != id && (
                    <li
                      key={id}
                      onClick={() => inputFn(id)}
                      className={`bg-transparent border cursor-pointer bg-white hover:bg-cust-red hover:text-white w-80 rounded-full m-1 text-center`}
                    >
                      {" "}
                      {id}{" "}
                    </li>
                  )
              )}
            </ul>
          </div></>}
          {fabric && (
            <select
              onChange={(e) => setMetrics(e.target.value)}
              className=" border cursor-pointer hover:shadow focus:shadow flex border-cust-red focus:ring-0 outline-none overflow-clip w-80 rounded-full m-2 text-center bg-white justify-between items-center "
              name="metrics"
              id="metrics"
            >
              <option value=""> Metric? </option>
              <option value="CM"> CM(Centimeters) </option>
              <option value="Inches"> Inches </option>
            </select>
          )}

          {metrics != "" && (
            <div className=" flex justify-center items-center">
              <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                <span className=" w-full text-center left-0 h-full text-white">
                  Width in {metrics}{" "}
                </span>
                <input
                  type="text"
                  className=" h-full text-center bg-white w-44 focus:ring-0 outline-none border-none "
                  name="width"
                  id="width"
                  onChange={(e) => setWidth(e.target.value)}
                />
              </div>
            </div>
          )}
          {metrics != "" && (
            <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
              <span className=" w-full text-center left-0 h-full text-white">
                Length in {metrics}
              </span>
              <input
                type="text"
                className=" h-full text-center bg-white w-44 focus:ring-0 outline-none border-none "
                name="length"
                id="length"
                onChange={(e) => setLength(e.target.value)}
              />
            </div>
          )}
          {!res && actArea > 0 && (
            <div> Entered Quantity is not available. </div>
          )}
          {area > 0 && <div> {res} </div>}
          {cat.length > 0 && res && category == "Outlook" && (
            <div className=" w-full m-2 p-1 ">
              <p className="  text-center text-cust-red"> Select System </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                {cat.map((ele) => (
                  <label key={ele.id} className="cursor-pointer">
                    <input
                      type="radio"
                      className="peer sr-only"
                      name="system"
                      value={ele.id}
                      onChange={(e) => handleSys(e.target.value)}
                    />
                    <div className="w-40 max-w-xl rounded-md bg-white peer-checked:bg-red-50 ring-1 ring-offset-1 ring-transparent peer-checked:ring-cust-red p-4 text-gray-500 transition-all hover:shadow peer-checked:text-cust-red ">
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-semibold uppercase">
                            {ele.name}
                          </p>
                          <div>
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="flex items-end justify-between">
                          <p>
                            <span className=" font-bold">
                              {" "}
                              {ele.price}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}

          {system.length > 0 && (
            <div className=" w-full m-2 p-1 ">
              <p className="  text-center text-cust-red"> Select Medium </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                {system.map((ele) => (
                  <RadioOption
                    eleID={ele.id}
                    eleCat="medium"
                    eleName={ele.name}
                    elePrice={ele.price}
                    func={handleMedium}
                  />
                ))}
              </div>
            </div>
          )}
          {medium.length > 0 && (
            <div className=" w-full m-2 p-1 ">
              <p className="  text-center text-cust-red"> Select Operation </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                {medium.map((ele) => (
                  <RadioOption
                    key={ele.id}
                    eleID={ele.id}
                    eleCat="operation"
                    eleName={ele.name}
                    elePrice={ele.price}
                    func={handleOutlookOp}
                  />
                ))}
              </div>
            </div>
          )}
          {cat.length > 0 && res && category != "Outlook" && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Select Operation </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                {cat.map((ele) => (
                  <RadioOption
                    key={ele.id}
                    eleID={ele.id}
                    eleCat="operation"
                    eleName={ele.name}
                    elePrice={ele.price}
                    func={handleOp}
                  />
                ))}
              </div>
            </div>
          )}
          {operation.length > 0 && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Operation Side </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                <RadioOption
                  eleID="Left"
                  eleCat="opSide"
                  eleName="Left"
                  func={setOperatingSide}
                />
                <RadioOption
                  eleID="Right"
                  eleCat="opSide"
                  eleName="Right"
                  func={setOperatingSide}
                />
              </div>
            </div>
          )}

          {operation.length > 0 && category != "Outlook" && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Select Mechanism </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                {!fabric.includes("35mm") || operate != "Manual" ? (
                  operation.length > 0 &&
                  operation.map((ele) => (
                    <RadioOption
                      key={ele.id}
                      eleID={ele.id}
                      eleCat="mechanism"
                      eleName={ele.name}
                      elePrice={ele.price}
                      func={handleMotorType}
                    />
                  ))
                ) : (
                  <RadioOption
                    key={1}
                    eleID={1}
                    eleCat="mechanism"
                    eleName="Cord/Lock"
                    func={handleMotorType}
                  />
                )}
              </div>
            </div>
          )}
          {motorType.length > 0 && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Select Motor </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                {motorType.map((ele) => (
                  <RadioOption
                    key={ele.id}
                    eleID={ele.id}
                    eleCat="motor"
                    eleName={ele.name}
                    elePrice={ele.price}
                    func={handleMotor}
                  />
                ))}
              </div>
            </div>
          )}
          {category == "New Aluminium" && motorTypeFinal == "Wired" && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Add Switch </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                <RadioOption
                  eleID="Yes"
                  eleCat="switch"
                  eleName="Yes"
                  func={setSwitchFinal}
                />
                <RadioOption
                  eleID="No"
                  eleCat="switch"
                  eleName="No"
                  func={setSwitchFinal}
                />
              </div>
            </div>
          )}
          {category == "New Wood & Faux Wood" && motorTypeFinal == "Monochain" && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Select Ball </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                <RadioOption
                  eleID="Chain"
                  eleCat="ball"
                  eleName="Chain"
                  func={setBallFinal}
                />
                <RadioOption
                  eleID="Cord"
                  eleCat="ball"
                  eleName="Cord"
                  func={setBallFinal}
                />
              </div>
            </div>
          )}
          {category == "New Wood & Faux Wood" &&
            (motorTypeFinal == "Cord / Lock" ||
              motorTypeFinal == "Monochain") && (
              <div className=" w-full m-2 p-1 ">
                <p className=" text-xl text-center text-cust-red"> Select Ladder </p>
                <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                  <RadioOption
                    eleID="String Ladder"
                    eleCat="ladder"
                    eleName="String Ladder"
                    func={handleLadder}
                  />
                  <RadioOption
                    eleID="Tape Ladder"
                    eleCat="ladder"
                    eleName="Tape Ladder"
                    func={handleLadder}
                  />
                </div>
              </div>
            )}
          {motor.length > 0 && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Select Remote </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                {motor &&
                  motor.map((ele) => (
                    <RadioOption
                      key={ele.id}
                      eleID={ele.id}
                      eleCat="remote"
                      eleName={ele.name}
                      elePrice={ele.price}
                      func={handleRemote}
                    />
                  ))}
              </div>
            </div>
          )}
          {category == "Outlook" && operate == "Motorised" && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Add on ? </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                <RadioOption
                  eleID="Crank"
                  eleCat="addon"
                  eleName="Crank"
                  func={handleAddOn}
                />
              </div>
            </div>
          )}
          {category == "Roller" && (
            <div className=" w-full m-2 p-1 ">
              <p className=" text-xl text-center text-cust-red"> Add on ? </p>
              <div className=" flex flex-wrap items-center justify-center w-full p-4 gap-3 ">
                <RadioOption
                  eleID="Pelmet/Fascia"
                  eleCat="addon"
                  elePrice={30}
                  eleName="Pelmet/Fascia"
                  func={handleAddOn}
                />
              </div>
            </div>
          )}
        </div>
        {fabric && (
          <div className=" w-full p-2 flex justify-between">
            <span>
              Price of {fabric} ({fabricStyle}){" "}
              {ballFinal != "" && <> ({ballFinal}) </>}{" "}
              {ladderFinal != "" && <> ({ladderFinal}) </>} :
            </span>
            <span className="  text-cust-red ">
              {"\u20B9"} {fabricPrice}
            </span>
          </div>
        )}
        {operationPrice != 0 && (
          <div className=" w-full p-2 flex justify-between">
            <span>Price for operation: ({operate}) </span>
            <span className="  text-cust-red ">
              {"\u20B9"} {operationPrice}/sqft
            </span>
          </div>
        )}
        {addOnPrice != 0 && (
          <div className=" w-full p-2 flex justify-between">
            <span>Price for add on: ({addOn}) </span>
            <span className="  text-cust-red ">
              {"\u20B9"} {addOnPrice}
            </span>
          </div>
        )}
        {length > 0 && width > 0 && (
          <div className="flex w-full p-2 justify-between">
            <span>Area:</span>{" "}
            <span className=" flex  text-cust-red "> {area} Sqft </span>
          </div>
        )}
        {motorPrice > 0 && (
          <div className=" w-full p-2 flex justify-between">
            <span>Price for Motor: ({motorFinal}) </span>
            <span className="  text-cust-red ">
              {"\u20B9"} {motorPrice}
            </span>
          </div>
        )}
        {remotePrice > 0 && (
          <div className=" w-full p-2 flex justify-between">
            <span>Price for Remote: ({remote}) </span>
            <span className="  text-cust-red ">
              {"\u20B9"} {remotePrice}
            </span>
          </div>
        )}
        <hr />
        {fabric && (
          <div className=" w-full p-2 flex justify-between">
            <span>Price in Total:</span>
            {category == "Outlook" ? (
              <span className="  text-cust-red ">
                {"\u20B9"}{" "}
                {Number(
                  (fabricPrice + operationPrice) * area +
                    motorPrice +
                    remotePrice +
                    addOnPrice
                ).toFixed(2)}
              </span>
            ) : (
              <span className="  text-cust-red ">
                {"\u20B9"}{" "}
                {Number(
                  (fabricPrice + operationPrice + addOnPrice) * area +
                    motorPrice +
                    remotePrice
                ).toFixed(2)}
              </span>
            )}
          </div>
        )}
        <div className="flex justify-around m-3 ">
          {res ? (
            <motion.button
              whileTap={{ translateX: 4 }}
              onClick={submitOrder}
              className=" shadow-md hover:shadow-cust-red  cursor-pointer border border-cust-red w-fit ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white pl-8 pr-8 pt-2 pb-2 rounded-full "
            >
              Add Product
            </motion.button>
          ) : (
            <div className=" flex-col">
              <motion.button
                disabled
                className=" shadow-md cursor-not-allowed border w-fit ml-4 mr-4 text-cust-white bg-cust-black pl-8 pr-8 pt-2 pb-2 rounded-full "
              >
                Add Product
              </motion.button>
            </div>
          )}
        </div>
        <p>{alert} </p>
      </div>
    </div>
  );
};

export default NewOrder;
