import React, { useState, useEffect } from "react";
import { app } from "../firebase";

import {
  where,
  query,
  collection,
  getDocs,
  getFirestore,
  updateDoc,
  deleteDoc,
  doc,
  limit,
  orderBy,
  startAt,
} from "firebase/firestore";

import UserList from "./UserList";

const Users = () => {
  const [users, setUsers] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [currTab, setCurrTab] = useState(false);
  
  const db = getFirestore(app);


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getUsers = async () => {
    const users = await getDocs(
      query(collection(db, "users"))
    );
    return users.docs.map((doc) => doc.data());
  };

  // Fetch Users
  const fetchUsers = async () => {
    await getUsers().then((data) => {
      setUsers(data);
    });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  //Give Access to user
  const toggleAccess = (user) => {
    const userRef = doc(db, "users", user.email);
    updateDoc(userRef, {
      access: !user.access,
    }).then(fetchUsers())
  };

  const toggleAdmin = (user) => {
    const userRef = doc(db, "users", user.email);
    updateDoc(userRef, {
      isAdmin: !(user.isAdmin),
    }).then(console.log("Done!"), document.getElementById(user.email).classList.add('hidden'));
  };

  // Delete person
  const deletePerson = async (user) => {
    const userRef = doc(db, "users", user.email);
    await deleteDoc(userRef).then(fetchUsers());
  };

  //Search fun
  const search = (data) => {
    return data.filter(item => item.name.toLowerCase().includes(searchQuery) || item.orgName.toLowerCase().includes(searchQuery) || item.email.toLowerCase().includes(searchQuery) || item.phone.toLowerCase().includes(searchQuery) );
  }
  return (
    <div className="flex h-full min-h-screen justify-center">
      <div className=" w-full card max-w-5xl p-4 mt-16 mb-16 items-center bg-cust-white rounded-lg ">
        <>
              <div className=" flex w-full uppercase space-x-1 rounded-lg bg-transparent p-1">
                <div onClick={ (e) => setCurrTab(false) } className={ !currTab ? ' cursor-pointer w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-none bg-cust-red text-cust-white shadow': ' cursor-pointer w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-cust-red focus:outline-none hover:bg-white' }> pending users </div>
                <div onClick={ (e) => setCurrTab(true) } className={ currTab ? ' cursor-pointer w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-none bg-cust-red text-cust-white shadow': ' cursor-pointer w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-cust-red focus:outline-none hover:bg-white' }> approved users </div>
                </div>
                <div  className="flex flex-col items-center space-x-1 rounded-xl bg-transparent p-1">
              <input
                type="text"
                className=" h-full rounded-full m-3 mr-5 text-center focus:shadow bg-white w-fit focus:ring-0 outline-none border-none "
                name="search"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className=" flex w-full flex-col gap-1">
              {users &&
                search(users).filter(user => user.access == currTab).map((user) => (
                  <UserList key={user.email} item={user} toggleAccess={toggleAccess} deletePerson={deletePerson} />
                ))}
                </div>
                </div>
        </>
      </div>
    </div>
  );
};

export default Users;
