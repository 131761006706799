import React from 'react'


const bottom_headers = [
    "Prepared By",
    "Checked by",
    "Assembly By",
    "Channel Cutting",
    "Fabric Cutting",
    "Components",
    "Packed By",
    "Dispatch By",
    "Channel"
  ];

const BottomTable = ({ adminData }) => {
  return (
    
    <table style={{ textAlign: "center" }}>
    <thead>
      <tr>
          <th
            style={{
              border: "1px solid black",
              backgroundColor: "#757575",
              color: "white",
            }}
            className=' text-sm p-1'
          >
          </th>
        {bottom_headers.map((colName) => (
          <th
            key={colName}
            style={{
              border: "1px solid black",
              backgroundColor: "#757575",
              color: "white",
            }}
            className=' text-sm p-1'
          >
            {colName}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      { ['Name', 'Date', 'Signature'].map( (row, index) => (
        <tr key={index}>
        <td style={{ border: "1px solid black" }}>
        <input
          type='text'
          value={row}
          disabled
          style={{ border: "none", fontSize: "smaller", width: "100%" }}
        />
      </td>
          {bottom_headers.map((item, iindex) => (
          <td key={iindex} style={{ border: "1px solid black" }}>
          <input
            type='text'
            defaultValue={iindex === 0 ? ( index === 0 ? adminData.name : ( index === 1 ? ( new Date()).toLocaleDateString() : '') ): ''}
            style={{ border: "none", fontSize: "smaller", width: "100%" }}
          />
        </td>
          ))}
        </tr>
      ) ) }
    </tbody>
  </table>
  )
}

export default BottomTable
