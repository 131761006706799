import React, { useEffect, useState, Fragment } from "react";
import {
  where,
  query,
  collection,
  getDocs,
  getDoc,
  getFirestore,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { app } from "../firebase";
import NewOrder from "./NewOrder";
import { motion } from "framer-motion";
import { FiPlus } from "react-icons/fi";
import Order from "./Order";
import Loader from "./minis/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { MdDelete, MdAdd, MdCancel, MdRefresh } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { RxCross1 } from "react-icons/rx";
import { Transition, Dialog } from "@headlessui/react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import emailjs from "@emailjs/browser";

import EmailTemp from "./minis/EmailTemp";
import { render } from "@react-email/components";
import FullLoader from "./minis/FullLoader";
import JobCard from './modules/JobCard/JobCard';
import NotFound from "./NotFound";


const db = getFirestore(app);
const deliveries = ["Surface", "Air"];

const Project = ({ projectID }) => {
  const { orderNumber } = useParams();
  const [project, setProject] = useState();

  useEffect(() => {
    fetchOrder(projectID ? projectID : orderNumber).then((project) =>
      setProject(project)
    ).catch(error => console.log(error));
  }, []);
  return (
    <>
      {project && (
        <ProjectContainer project={project} setProject={setProject} />
      ) }
    </>
  );
};

const fetchOrder = async (orderID) => {
  const projRef = doc(db, "orders", orderID);
  const order = await getDoc(projRef);
  return order.data();
};

const ProjectContainer = ({ project, setProject }) => {
  const [orders, setOrders] = useState([]);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const dispatch = useDispatch();
  const projectID = project.projectID;
  const [status, setStatus] = useState(project.status);
  const [makeNote, setMakeNote] = useState(false);
  const [notes, setNotes] = useState("");
  const [docketNumber, setDocketNumber] = useState();
  const [exists, setExists] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userDet, setUserDet] = useState();
  const loggedInUser = useSelector((state) => (state.loggedInUser));
  const pricelist = useSelector((state) => state.pricelist);
  const [deliveryMode, setDeliveryMode] = useState(deliveries[0]);
  const [freightPrice, setFreightPrice] = useState(0);
  const [packagingPrice, setPackagingPrice] = useState(0);
  const [scLoader, setScLoader] = useState(false);

  var isAdmin = false;
  var adminData = {};
  if(loggedInUser){
    isAdmin = JSON.parse(loggedInUser)?.isAdmin;
    adminData = JSON.parse(loggedInUser);
  }
  var orderSts = [
    "Incomplete",
    "Under Review",
    "Order Confirmed",
    "In Production",
    "Order Dispatched",
  ];
  if (status === 1) {
    orderSts = [
      "Declined",
      "Under Review",
      "Order Confirmed",
      "In Production",
      "Order Dispatched",
    ];
  }
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogStep, setDialogStep] = useState(false);

  const [denyDialog, setDenyDialog] = useState(false);
  const [acceptDialog, setAcceptDialog] = useState(false);
  const [dispatchDialog, setDispatchDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [jobCardDialog, setjobCardDialog] = useState(false);

  const preventEdit = (status > 1 && !isAdmin) || (status === 5);

  const [subtotal, setSubtotal] = useState(0);

  const getUser = async () => {
    const projects = await getDocs(
      query(collection(db, "users"), where("email", "==", project.user))
    );
    projects.docs.map((doc) => setUserDet(doc.data()));
  };
  const getProject = async () => {
    const products = await getDocs(
      query(collection(db, `orders/${projectID}/products`), orderBy("orderID", "desc"))
    );
    return products.docs.map((doc) => doc.data());
  };

  // Fetch Projects
  const fetchOrders = async () => {
    await getProject().then((data) => {
      setOrders(data);
    });
  };
  useEffect(() => {
    fetchOrders();
    getUser();
  }, []);

  useEffect(() => {
    makeTotal();
  }, [orders]);

  const toggleNewOrder = () => {
    setIsNewOrder(!isNewOrder);
    fetchOrders();
    fetchPricelist();
  };

  const toggleNote = () => {
    setMakeNote(!makeNote);
  };
  // Accept order by Admin

  const acceptOrder = (projectID) => {
    setScLoader(true);
    const projRef = doc(db, "orders", projectID);
    updateDoc(projRef, {
      status: 3,
      freightPrice: freightPrice,
      packagingPrice: packagingPrice,
    }).then(() => {
      fetchOrder(projectID).then((order) => {
        setStatus(3);
        var mesTemp = render(
          <EmailTemp
            order={order}
            remarks={``}
            heading={`Order ${project.projectName} is accepted`}
            mainText={`Hey ${userDet?.name}! Thank you for your order! Your order No ${project.pONumber} has been confirmed by our team and will be under production soon.`}
            emailBodyText={`We will let you know once the order is dispatched. You can also check the status of your order on the orders section. Do get in touch with us for any queries or assistance. We hope to see you soon on My Louverline.`}
            userDet={userDet}
            subtotal={subtotal}
            products={orders}
          />
        );
        var templateParams = {
          useremail: `${userDet.email}`,
          emailsubject: `Order ${order.projectName} is accepted`,
          emailbody: mesTemp,
        };
        // Send Email Fuction
        emailjs
          .send(
            process.env.REACT_APP_EMAILJS_GMAIL_SERVICE_KEY,
            process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
            templateParams,
            process.env.REACT_APP_EMAILJS_API_KEY
          )
          .then(
            function(response) {
              console.log("SUCCESS!", response.status, response.text);
              setAcceptDialog(false);
              setNotes("");
              setScLoader(false);
            },
            function(error) {
              console.log("FAILED...", error);
              window.alert("Failed to Send Email");
              setScLoader(false);
            }
          );
      });
    });
  };
  // Move to production
  function moveToProduction(projectID) {
    const projRef = doc(db, "orders", projectID);
    updateDoc(projRef, {
      status: 4,
    }).then(() => setStatus(4));
  }

  //  dispatch Order
  function dispatchOrder(projectID) {
    const projRef = doc(db, "orders", projectID);
    updateDoc(projRef, {
      status: 5,
      docketNumber: docketNumber,
    }).then(() => {
      fetchOrder(projectID).then((order) => {
      setStatus(5)
      var mesTemp = render(
        <EmailTemp
          order={order}
          remarks={``}
          heading={`Order ${project.projectName} is dispatched.`}
          mainText={`Hey ${userDet?.name}! Your order No ${project.pONumber} has been dispatched and the tracking details is given below.`}
          emailBodyText={`Thank you once again for your order. We look forward to serving you soon! Do get in touch with us for any queries or assistance. We hope to see you soon on My Louverline.`}
          userDet={userDet}
          subtotal={subtotal}
          products={orders}
        />
      );
      var templateParams = {
        useremail: `${userDet.email}`,
        emailsubject: `Order ${order.projectName} is dispatched`,
        emailbody: mesTemp,
        bccaddr: "orders@louverlineblinds.com",
      };
      // Send Email Fuction
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_GMAIL_SERVICE_KEY,
          process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
          templateParams,
          process.env.REACT_APP_EMAILJS_API_KEY
        )
        .then(
          function(response) {
            console.log("SUCCESS!", response.status, response.text);
            setDispatchDialog(false);
            setNotes("");
            setScLoader(false);
          },
          function(error) {
            console.log("FAILED...", error);
            window.alert("Failed to Send Email");
            setScLoader(false);
          }
        );
    });
  })
  }

  // Reject Order by admin
  const rejectOrder = (projectID) => {
    setScLoader(true);
    const projRef = doc(db, "orders", projectID);
    updateDoc(projRef, {
      status: 1,
      adminremarks: notes,
    }).then(() => {
      fetchOrder(projectID).then((order) => {
        var mesTemp = render(
          <EmailTemp
            order={order}
            heading={`Order ${project.projectName} was declined`}
            mainText={`Hey ${userDet?.name}! We've got some bad news. Your order No ${project.pONumber} has unfortunately been declined.`}
            emailBodyText={`Head over to the my orders page and do ensure you read the remarks section with reason behind cancellation of your order. Do get in touch with us for any queries or assistance. We hope to see you soon on My Louverline.`}
            userDet={userDet}
            subtotal={subtotal}
            products={orders}
          />
        );
        var templateParams = {
          useremail: `${userDet.email}`,
          emailsubject: `Order ${project.projectName} was declined`,
          emailbody: mesTemp,
        };
      // Send Email Fuction
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_GMAIL_SERVICE_KEY,
          process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
          templateParams,
          process.env.REACT_APP_EMAILJS_API_KEY
        )
        .then(
          function(response) {
            console.log("SUCCESS!", response.status, response.text);
            setStatus(1);
            setDenyDialog(false);
            setNotes("");
            setScLoader(false);
          },
          function(error) {
            console.log("FAILED...", error);
            window.alert("FAILED");
            setScLoader(false);
          }
        );
      })
    });
  };

  // Confirm Order
  const confirmOrder = (projectID) => {
    var currDate = new Date();
    var currTime = currDate.getTime();
    // var mesTemp = ReactDOMServer.renderToStaticMarkup(emailTemp);
    var mesTemp = render(
      <EmailTemp
        order={project}
        remarks={`Note by you: ${notes}`}
        heading={`Order ${project.projectName} is under review`}
        mainText={`Hey ${userDet?.name}! Thank you for your order! Your order No. ${project.pONumber} has been recieved and is currently under review by our team.`}
        userDet={userDet}
        emailBodyText={`We will let you know once the order is confirmed. You can also check the status of your order on the orders section. Do get in touch with us for any queries or assistance. We hope to see you soon on My Louverline.`}
        subtotal={subtotal}
        products={orders}
        deliveryMode={deliveryMode}
      />
    );
    var templateParams = {
      useremail: `${userDet.email}`,
      emailsubject: `Order ${project.projectName} placed successfully`,
      emailbody: mesTemp,
      bccaddr: "orders@louverlineblinds.com",
    };
    const projRef = doc(db, "orders", projectID);
    setScLoader(true);
    updateDoc(projRef, {
      status: 2,
      time: currTime,
      deliveryMode: deliveryMode,
      remarks: notes,
    }).then(() => {
      // Send Email Fuction
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_GMAIL_SERVICE_KEY,
          process.env.REACT_APP_EMAILJS_TEMPLATE_KEY,
          templateParams,
          process.env.REACT_APP_EMAILJS_API_KEY
        )
        .then(
          function(response) {
            console.log("SUCCESS!", response.status, response.text);
            setStatus(2);
            setDialogIsOpen(false);
            setDialogStep(false);
            setNotes("");
            setScLoader(false);
          },
          function(error) {
            console.log("FAILED...", error);
            window.alert("FAILED");
            setScLoader(false);
          }
        );
    });
  };

  // Delete order
  const deleteOrder = (orderID) => {
    deleteDoc(doc(db, "orders", orderID)).then(setExists(false));
  };

  const addRemark = (projectID) => {
    const projRef = doc(db, "orders", projectID);
    if (isAdmin) {
      updateDoc(projRef, {
        adminremarks: notes,
      }).then(setMakeNote(false));
    } else {
      updateDoc(projRef, {
        remarks: notes,
      }).then(setMakeNote(false));
    }
  };

  const makeTotal = () => {
    let subT = orders.reduce(function(accumulator, item) {
      return accumulator + item.totalPrice * item.quantity;
    }, 0);
    // console.log(subT);
    setSubtotal(subT);
  };

  // fetch Pricelist

  const fetchPricelist = async () => {
    setIsLoading(true);
    if (pricelist.length === 0) {
      try {
        const pricelist = await axios.get(process.env.REACT_APP_PRICELIST);
        dispatch({
          type: "SET_PRICELIST",
          pricelist: pricelist.data.data,
        });
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const refreshPage = () => {
    setIsNewOrder(false);
    fetchOrders();
  };

  // Dialog step change
  const changeStep = (ele) => {
    setDialogStep(true);
    setDeliveryMode(ele);
  };

  return (
    <>
      <div
        className={
          " w-full bg-white items-center p-2 justify-center rounded-lg"
        }
      >
        {exists ? (
          <>
            {/* Product info 1 liner  */}
            <div className=" text-base md:text-lg text-center bg-transparent font-semibold font-belleza capitalize text-cust-red">
              {" "}
              {project.projectName}{" "}
            </div>
            {project.pONumber && (
              <div className=" text-xs md:text-sm text-center text-cust-black">
                {" "}
                PO Number: {project.pONumber}{" "}
              </div>
            )}
            <div className=" text-xs md:text-sm mb-2 text-center text-cust-black">
              {" "}
              Order ID: {project.projectID}{" "}
            </div>
            {project.docketNumber && (
              <div className=" text-xs md:text-sm text-center text-cust-black">
                {" "}
                Docket Number: {project.docketNumber}{" "}
              </div>
            )}
            <div className=" md:hidden text-xs md:text-sm mb-2 text-center text-cust-black">
              {orderSts[status > 1 ? status - 1 : 0]}
            </div>
            <div className=" hidden md:block text-xs">
              <Stepper
                alternativeLabel
                activeStep={status > 1 ? status - 1 : 0}
              >
                {orderSts.map((step) => (
                  <Step key={step}>
                    <StepLabel> {step} </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {/* List section */}
            {userDet && (
              <>
                <div className=" text-sm flex md:mt-2 md:mb-1 flex-col">
                  {/* User Details  */}

                  {isAdmin && (
                    <Link to={`/user/${userDet.email}`}>
                      <div className="bg-cust-white flex flex-col cursor-pointer hover:shadow-md rounded-lg m-2 p-1">
                        <span className=" text-cust-red font-bold">
                          {" "}
                          {userDet.name}
                        </span>
                        <div className=" grid grid-cols-1 items-end md:grid-cols-3 gap-2">
                          <span> Organisation: {userDet.orgName} </span>
                          <span> Email: {userDet.email.toLowerCase()} </span>
                          <span> Phone: {userDet.phone} </span>
                        </div>
                      </div>
                    </Link>
                  )}

                  {/* Making Note/Remarks section */}

                  <div className=" flex justify-between mt-3 gap-2 ">
                  {!makeNote ? (
                    <button
                      onClick={toggleNote}
                      className="cursor-pointer items-center justify-center flex hover:shadow-cust-red text-center px-3 shadow-md bg-cust-red border border-cust-red text-cust-white py-1 rounded-md"
                    >
                      <MdAdd /> Remarks
                    </button>
                  ) : (
                    <button
                      onClick={toggleNote}
                      className="cursor-pointer  justify-center flex border items-center border-cust-red hover:shadow text-center bg-transparent text-cust-red py-1 px-3 rounded-md"
                    >
                      <MdCancel /> Cancel
                    </button>
                  )}
                  {/* <button
                    onClick={refreshPage}
                    className="cursor-pointer items-center justify-center flex hover:shadow-cust-red text-center w-1/4 shadow-md bg-cust-red border border-cust-red text-cust-white px-3 py-1 rounded-md"
                  >
                    <MdRefresh /> Refresh
                  </button> */}
                </div>

                  {/* Note/Remarks Text Area section  */}

                  {makeNote && (
                  <div className=" w-full md:w-1/3 mt-2 mb-2">
                    <textarea
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                      name="newNote"
                      placeholder={
                        isAdmin
                          ? "Any message for the customer?"
                          : "Any special request or message for the team?"
                      }
                      id="newNote"
                      cols="30"
                      rows="3"
                      className="w-full rounded-md bg-cust-white focus:outline-none outline-none"
                    >
                      {" "}
                    </textarea>
                    <button
                      onClick={() => addRemark(projectID)}
                      className="cursor-pointer justify-center flex hover:shadow-cust-red text-center w-1/3  shadow-md bg-cust-red text-cust-white r-0 pt-1 pb-1 rounded-md"
                    >
                      Add Note
                    </button>
                  </div>
                )}

                  {/* Note/Remarks section  */}

                  <div className=" grid grid-cols-2 m-3">
                    {project.remarks && project.remarks !== "" && (
                      <div>User's Note: {project.remarks}</div>
                    )}
                    {project.adminremarks && project.adminremarks != "" && (
                      <div>Admin's Note: {project.adminremarks}</div>
                    )}
                  </div>
                  {/* Orders Details Section */}

                  {orders.length > 0 ? (
                    <div>
                      <div className=" font-bold flex justify-around items-center p-3 ">
                        {/* <span className=" hidden md:block w-40">Created At</span> */}
                        <span className=" w-40">Product</span>
                        <span className=" hidden md:block w-40"> Width </span>
                        <span className=" hidden md:block w-40"> Length </span>
                        <span className=" hidden md:block w-40">
                          {" "}
                          Operation{" "}
                        </span>
                        <span className=" w-40"> Quantity </span>
                        <span className=" hidden md:block w-40"> Price </span>
                        <span className=" w-40"> Action </span>
                      </div>
                      {orders.length > 0 &&
                        orders.map((order) => (
                          <motion.div
                            initial={{ opacity: 0, y: -100 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -100 }}
                            key={order.orderID}
                          >
                            <Order
                              projectID={projectID}
                              refreshPage={refreshPage}
                              status={status}
                              order={order}
                            />
                          </motion.div>
                        ))}
                      <div className=" flex flex-col">
                        <div className=" pr-6 pl-6 pt-3 flex justify-between w-full font-bold md:text-base">
                          <span>Subtotal (Excl. of GST):</span>
                          <span className=" text-cust-red">
                            {" "}
                            {"\u20B9"}
                            {subtotal?.toFixed(2)}{" "}
                          </span>
                        </div>
                        {project.deliveryMode ?
                          <div className=" pr-6 pl-6 pt-3 flex justify-between w-full">
                            <span>Freight: ({project.deliveryMode}) </span>
                            <span className=" text-cust-red">
                              {" "}
                              {"\u20B9"}
                              {(project.freightPrice ? Number(project.freightPrice) : 0)}{" "}
                            </span>
                          </div> : <></>
                        }
                        {project.packagingPrice ?
                          <div className=" pr-6 pl-6 pt-3 flex justify-between w-full">
                            <span>Packaging: </span>
                            <span className=" text-cust-red">
                              {" "}
                              {"\u20B9"}
                              {project.packagingPrice}{" "}
                            </span>
                          </div> : <></>}
                        <Divider />
                          <div className=" pr-6 pl-6 pt-3 flex justify-between w-full font-bold md:text-base">
                            <span>Total (Excl. of GST):</span>
                            <span className=" text-cust-red">
                              {" "}
                              {"\u20B9"}
                              {(
                                subtotal +
                                (project.freightPrice ? Number(project.freightPrice) : 0) +
                                (project.packagingPrice ? Number(project.packagingPrice) : 0)
                              ).toFixed(2)}{" "}
                            </span>
                          </div>
                      </div>
                    </div>
                  ) : (
                    <div> Place your first order by clicking below!</div>
                  )}
                  {!isAdmin && status < 2 && (
                    <span>
                      {" "}
                      Please confirm your order after adding all your required
                      products and note that you cannot edit the order after the
                      order is accepted.{" "}
                    </span>
                  )}

                  {/* New Product Section starts */}

                  {!preventEdit && (
                    <div className=" mt-3 mb-3 flex flex-col items-center">
                      {!isNewOrder && (
                        <motion.button
                          onClick={() => toggleNewOrder()}
                          whileTap={{ translateY: 4 }}
                          className=" shadow-md relative z-0 m-2 w-fit items-center pl-8 pr-8 pt-2 pb-2 hover:shadow-cust-red flex cursor-pointer border border-cust-red ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white  rounded-full "
                        >
                          <FiPlus /> Add Product
                        </motion.button>
                      )}

                      {/* New Order Dialog? */}

                      <Transition appear show={isNewOrder} as={Fragment}>
                        <Dialog
                          as="div"
                          className=" relative z-10"
                          onClose={() => setIsNewOrder(false)}
                        >
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-black/25" />
                          </Transition.Child>

                          <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                              >
                                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                  <motion.button
                                    onClick={() => toggleNewOrder()}
                                    whileTap={{ translateY: 4 }}
                                    className=" shadow-md w-fit m-2 items-center pl-8 pr-8 pt-2 pb-2 hover:shadow-cust-red flex cursor-pointer border border-cust-red ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white  rounded-full "
                                  >
                                    Go Back
                                  </motion.button>
                                  <div className=" flex flex-col md:flex-row w-full">
                                    <div className="text-left flex flex-col w-full md:w-2/5 p-4 bg-white rounded-lg">
                                      <h1 className=" text-center text-lg text-cust-red">
                                        Please Note:
                                      </h1>
                                      1. Ensure the stock is available through
                                      the stock check platform before placing
                                      orders. <br />
                                      2. The minimum chargeable area for all the
                                      categories is 12 sqft except for Outlook
                                      where it's 27 sqft. <br />
                                      3. Orders submitted are not confirmed.
                                      They will be reviewed by our team and will
                                      be either approved or declined. Please
                                      check order page for status.
                                    </div>
                                    {isLoading ? (
                                      <div className=" w-full">
                                        <Loader />
                                      </div>
                                    ) : (
                                      <NewOrder
                                        className=" fixed"
                                        refreshPage={refreshPage}
                                        projectID={projectID}
                                      />
                                    )}
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition>

                      {/* New Order Same Page */}
                      {false && (
                        <motion.div
                          initial={{ opacity: 0, y: -200, scale: 0 }}
                          animate={{ opacity: 1, y: 0, scale: 1 }}
                          exit={{ opacity: 0, y: -200, scale: 0 }}
                          className=" flex shadow-md justify-center items-center flex-col md:flex-row bg-cust-white z-10"
                        >
                          <div className=" ">
                            <motion.button
                              onClick={() => toggleNewOrder()}
                              whileTap={{ translateY: 4 }}
                              className=" shadow-md w-fit m-2 items-center pl-8 pr-8 pt-2 pb-2 hover:shadow-cust-red flex cursor-pointer border border-cust-red ml-4 mr-4 text-cust-red hover:bg-cust-red hover:text-cust-white  rounded-full "
                            >
                              Go Back
                            </motion.button>
                            <div className="text-left flex w-80 flex-col  p-4 bg-cust-white rounded-lg">
                              <h1 className=" text-center text-lg text-cust-red">
                                Please Note:
                              </h1>
                              1. Ensure the stock is available through the stock
                              check platform before placing orders. <br />
                              2. The minimum chargeable area for all the
                              categories is 12 sqft except for Outlook where
                              it's 27 sqft. <br />
                              3. Orders submitted are not confirmed. They will
                              be reviewed by our team and will be either
                              approved or declined. Please check order page for
                              status.
                            </div>
                          </div>
                          {isLoading ? (
                            <div className=" w-full">
                              {" "}
                              <Loader />{" "}
                            </div>
                          ) : (
                            <NewOrder
                              className=" fixed"
                              refreshPage={refreshPage}
                              projectID={projectID}
                            />
                          )}
                        </motion.div>
                      )}
                    </div>
                  )}

                  {/* New Product Section ends */}

                  {/* Action Buttons for confirm/accept/reject/delete  */}

                  <span className=" mt-4 flex justify-end gap-2 ">
                    {status < 2 && (
                      <button
                        onClick={() => setDialogIsOpen(true)}
                        className=" flex shadow-md hover:shadow-blue-600 items-center justify-center p-2 rounded-md  hover:bg-blue-600 hover:text-white border text-blue-600 border-blue-600 "
                      >
                        Confirm <TiTick />
                      </button>
                    )}
                    {isAdmin && status === 2 && (
                      <button
                        onClick={() => setAcceptDialog(true)}
                        className="flex shadow-md hover:shadow-green-600 items-center justify-center p-2 rounded-md  hover:bg-green-600 hover:text-white border text-green-600 border-green-600 "
                      >
                        Accept <TiTick />
                      </button>
                    )}
                    {isAdmin && status === 2 && (
                      <button
                        onClick={() => setDenyDialog(true)}
                        className=" flex items-center shadow-md hover:shadow-cust-red justify-center p-2 rounded-md  hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
                      >
                        Decline <RxCross1 />
                      </button>
                    )}
                    {isAdmin && status === 3 && (
                      <button
                        onClick={() => moveToProduction(projectID)}
                        className="flex shadow-md hover:shadow-green-600 items-center justify-center p-2 rounded-md  hover:bg-green-600 hover:text-white border text-green-600 border-green-600 "
                      >
                        Move to Production <TiTick />
                      </button>
                    )}
                    {/* {isAdmin && (
                      <button
                        onClick={() => setjobCardDialog(true)}
                        className="flex shadow-md hover:shadow-green-600 items-center justify-center p-2 rounded-md  hover:bg-green-600 hover:text-white border text-green-600 border-green-600 "
                      >
                        Generate Job Cards <TiTick />
                      </button>
                    )} */}
                    {isAdmin && status === 4 && (
                      <button
                        onClick={() => setDispatchDialog(true)}
                        className="flex shadow-md hover:shadow-green-600 items-center justify-center p-2 rounded-md  hover:bg-green-600 hover:text-white border text-green-600 border-green-600 "
                      >
                        Dispatch <TiTick />
                      </button>
                    )}
                    {!preventEdit && (
                      <button
                        onClick={() => setDeleteDialog(true)}
                        className="flex shadow-md hover:shadow-cust-red items-center justify-center p-2 rounded-md  hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
                      >
                        Delete <MdDelete />
                      </button>
                    )}
                  </span>
                </div>

                {/* Order Confirmation by customer Dialog */}

                <Transition appear show={dialogIsOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className=" relative z-10"
                    onClose={() => setDialogIsOpen(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        {scLoader && <FullLoader />}
                      <div className="flex text-sm min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-full mb-4 border border-transparent bg-red-50 p-2 text-sm font-medium text-cust-red hover:bg-red-100 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                              onClick={() => setDialogIsOpen(false)}
                            >
                              <RxCross1 />
                            </button>
                            {dialogStep ? (
                              <>
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Please Note:
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Confirm your order only after adding all
                                    your required products and note that you
                                    cannot edit the order after the order is
                                    confirmed.
                                  </p>
                                </div>
                                <div className="mt-4 flex gap-3">
                                  <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-cust-red hover:bg-red-100 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                    onClick={() => setDialogStep(false)}
                                  >
                                    Go back
                                  </button>
                                  <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-cust-red px-4 py-2 text-sm font-medium text-white hover:bg-red-700 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                    onClick={() => confirmOrder(projectID)}
                                  >
                                    Confirm
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="mt-4 flex flex-col justify-center items-center gap-3">
                                  <div className=" w-full mt-2 mb-2">
                                    <textarea
                                      value={notes}
                                      onChange={(e) => {
                                        setNotes(e.target.value);
                                      }}
                                      name="newNote"
                                      placeholder="Any special request or message for us?"
                                      id="newNote"
                                      cols="30"
                                      rows="3"
                                      className=" required w-full rounded-md bg-transparent focus:outline-none outline-none"
                                    >
                                      {" "}
                                    </textarea>
                                  </div>
                                    <span className=" text-cust-red">
                                      Delivery Mode:
                                    </span>
                                  <div className=" flex justify-center items-center gap-3">
                                    {deliveries.map((delivery) => (
                                      <button
                                        onClick={() =>
                                          changeStep(delivery)
                                        }
                                        key={delivery}
                                        className="flex w-40 shadow-md hover:shadow-cust-red items-center justify-center p-2 rounded-md  hover:bg-cust-red hover:text-white border text-cust-red border-cust-red "
                                      >
                                        {delivery}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>

                {/* Order rejection by admin Dialog  */}

                <Transition appear show={denyDialog} as={Fragment}>
                  <Dialog
                    as="div"
                    className=" relative z-10"
                    onClose={() => setDenyDialog(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      {scLoader && <FullLoader />}
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Add a Note:
                            </Dialog.Title>
                            <div className="mt-4 flex flex-col justify-center items-center gap-3">
                              <div className=" w-full mt-2 mb-2">
                                <textarea
                                  value={notes}
                                  onChange={(e) => {
                                    setNotes(e.target.value);
                                  }}
                                  name="newNote"
                                  placeholder="Mention the reason for the rejection."
                                  id="newNote"
                                  cols="30"
                                  rows="3"
                                  className=" required w-full rounded-md bg-transparent focus:outline-none outline-none"
                                >
                                  {" "}
                                </textarea>
                              </div>
                              <div className="mt-4 flex gap-3">
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-cust-red hover:bg-red-100 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                  onClick={() => setDenyDialog(false)}
                                >
                                  Go back
                                </button>
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-cust-red px-4 py-2 text-sm font-medium text-white hover:bg-red-700 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    rejectOrder(projectID);
                                  }}
                                >
                                  Decline
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>

                {/* Order accepting by Admin Dialog  */}
                <Transition appear show={acceptDialog} as={Fragment}>
                  <Dialog
                    as="div"
                    className=" relative z-10"
                    onClose={() => setAcceptDialog(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        {scLoader && <FullLoader />}
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Extra charges:
                            </Dialog.Title>
                            <div className="mt-4 flex flex-col justify-center items-center gap-3">
                              <div className=" w-full mt-2 mb-2">
                                <div className=" flex justify-center items-center">
                                  <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                                    <span className=" w-full text-center left-0 h-full text-white">
                                      Frieght
                                    </span>
                                    <input
                                      type="text"
                                      className=" h-full text-center bg-white w-44 focus:ring-0 outline-none border-none "
                                      name="Charges"
                                      id="width"
                                      onChange={(e) =>
                                        setFreightPrice(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className=" flex justify-center items-center">
                                  <div className=" border border-cust-red overflow-clip w-80 rounded-full m-2 text-center bg-cust-red justify-between items-center flex">
                                    <span className=" w-full text-center left-0 h-full text-white">
                                      Packaging
                                    </span>
                                    <input
                                      type="text"
                                      className=" h-full text-center bg-white w-44 focus:ring-0 outline-none border-none "
                                      name="Charges"
                                      id="width"
                                      onChange={(e) =>
                                        setPackagingPrice(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4 flex gap-3">
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-cust-red hover:bg-red-100 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                  onClick={() => setAcceptDialog(false)}
                                >
                                  Go back
                                </button>
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-cust-red px-4 py-2 text-sm font-medium text-white hover:bg-red-700 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    acceptOrder(projectID);
                                  }}
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>
                
                {/* Order dispatch and put tracking link */}
                <Transition appear show={dispatchDialog} as={Fragment}>
                  <Dialog
                    as="div"
                    className=" relative z-10"
                    onClose={() => setDispatchDialog(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      {scLoader && <FullLoader />}
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                              as="h3"
                              className=" font-medium leading-6 text-gray-900"
                            >
                              Enter Docket Number:
                            </Dialog.Title>
                            <div className="mt-4 flex flex-col justify-center items-center gap-3">
                              <div className=" w-full mt-2 mb-2">
                                <textarea
                                  value={docketNumber}
                                  onChange={(e) => {
                                    setDocketNumber(e.target.value);
                                  }}
                                  name="docket"
                                  placeholder="Enter the docket number here:"
                                  cols="30"
                                  rows="2"
                                  className=" w-full rounded-md bg-transparent focus:outline-none outline-none"
                                >
                                  {" "}
                                </textarea>
                              </div>
                              <div className="mt-4 flex gap-3">
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-cust-red hover:bg-red-100 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                  onClick={() => setDispatchDialog(false)}
                                >
                                  Go back
                                </button>
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-cust-red px-4 py-2 text-sm font-medium text-white hover:bg-red-700 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    dispatchOrder(projectID);
                                  }}
                                >
                                  Dispatch
                                </button>
                              </div>
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>

                {/* Order Delete Dialog */}

                <Transition appear show={deleteDialog} as={Fragment}>
                  <Dialog
                    as="div"
                    className=" relative z-10"
                    onClose={() => setDeleteDialog(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                    {scLoader && <FullLoader />}
                      <div className="flex text-sm min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg font-medium leading-6 text-gray-900"
                                >
                                  Are you sure?
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                  Please confirm that you wish to delete this order. You will not be able to restore it later.
                                  </p>
                                </div>
                                <div className="mt-4 flex gap-3">
                                  <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-red-50 px-4 py-2 text-sm font-medium text-cust-red hover:bg-red-100 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                    onClick={() => setDeleteDialog(false)}
                                  >
                                    Go back
                                  </button>
                                  <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-cust-red px-4 py-2 text-sm font-medium text-white hover:bg-red-700 text-center focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                    onClick={() => deleteOrder(projectID)}
                                  >
                                    Delete
                                  </button>
                                </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>


                {/* Job Cards Dialog */}
                <Transition appear show={jobCardDialog} as={Fragment}>
                  <Dialog
                    as="div"
                    className=" relative z-10 w-full"
                    onClose={() => setjobCardDialog(false)}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                              as="h3"
                              className="text-lg font-medium leading-6 text-gray-900"
                            >
                              Job Card
                            </Dialog.Title>
                            <div>
                            <JobCard closeFn={() => setjobCardDialog(false)} order={project} products={orders} adminData={adminData} />
                            </div>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition>

              </>
            )}
            {false && (
              <EmailTemp
                order={project}
                remarks={`Reason: ${notes}`}
                heading={`Order ${project.projectName} was declined`}
                mainText={`Hey ${userDet?.name}! We've got some bad news. Your order No ${project.pONumber} has unfortunately been declined.`}
                emailBodyText={`Head over to the my orders page and do ensure you read the remarks section with reason behind cancellation of your order. Do get in touch with us for any queries or assistance. We hope to see you soon on My Louverline.`}
                userDet={userDet}
                subtotal={subtotal}
                products={orders}
              />
            )}
          </>
        ) : (
          <> Order {project.projectName} deleted </>
        )}
      </div>
    </>
  );
};

export default Project;
